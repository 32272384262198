import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "chart.js/auto";

import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import { ThemeProvider } from "./contexts/ThemeContext";
import { auth0Config } from "./config";
import { Auth0Provider, CacheLocation } from "@auth0/auth0-react";

const container = document.getElementById("root");
const root = createRoot(container!);
const providerConfig = {
  domain: auth0Config.domain || "",
  clientId: auth0Config.clientId || "",
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: auth0Config.apiAudience,
    scope: auth0Config.apiScope,
  },
  cacheLocation: "localstorage" as CacheLocation,
};
// console.log("providerConfig", providerConfig);

root.render(
  <Auth0Provider {...providerConfig}>
    <BrowserRouter>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
