import React from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { AddCircle } from "@mui/icons-material";

import AllergiesList from "./AllergiesList";
import { PatientAllergies } from "../../../api/jabsCentralApiPrivate";
import { usePatientPatientIdUpdatesGetPatientPatientIdUpdatesGetQuery } from "../../../api/jabsCentralApiPrivate";

// Styled component for the uploader container
const UploaderContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
  borderRadius: "6px",
  padding: "16px",
}));

// Styled component for the existing allergies list
const ExistingList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  margin: "0",
}));

// Interface for the component props
interface AllergiesProps {
  allergies: PatientAllergies[];
  id: string;
}

// Component to display and manage patient allergies
const Allergies = ({ allergies, id }: AllergiesProps) => {
  // Query hook to fetch patient updates
  const {
    data: updates,
    error: updatesError,
    isLoading: isUpdatesLoading,
    isFetching: isUpdatesFetching,
  } = usePatientPatientIdUpdatesGetPatientPatientIdUpdatesGetQuery({
    patientId: id,
  });

  let content = null;

  // Show loading state while fetching updates
  if (isUpdatesLoading || isUpdatesFetching) {
    content = (
      <Box>
        <Stack direction={"row"} alignItems="center">
          <IconButton disabled>
            <AddCircle />
          </IconButton>
          <Typography variant="h4">Allergies</Typography>
        </Stack>
        <UploaderContainer>
          <ExistingList dense>
            {allergies?.map((item, index) => (
              <ListItemButton key={index}>
                <ListItemText primary={item.description} />
              </ListItemButton>
            ))}
          </ExistingList>
          <Stack direction="row" sx={{ justifyContent: "center", mt: 6 }}>
            <CircularProgress color="secondary" />
          </Stack>
        </UploaderContainer>
      </Box>
    );
  } else if (updatesError) {
    // Show error message if there was an error fetching updates
    console.log(updatesError);
    content = (
      <div>
        Error:{" "}
        {updatesError
          ? String(updatesError) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    // Prepare proposed items from updates
    let proposedItems = [] as any[];
    if (updates && updates.allergies) {
      proposedItems = updates.allergies.map(({ allergy, updateId }) => ({
        item: allergy,
        id: updateId,
      }));
    }

    // Render the allergies list with existing and proposed items
    content = (
      <AllergiesList
        existingItems={allergies || []}
        proposedItems={proposedItems}
      />
    );
  }

  return <Box>{content}</Box>;
};

export default Allergies;
