// React imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// Redux imports
import { useDispatch } from "react-redux";
import { setSelectedPatient } from "../../redux/slices/selectedPatientSlice";

// API imports
import { usePatientGetPatientGetQuery } from "../../api/jabsCentralApiPrivate";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";

// MUI imports
import { Box } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridEventListener,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import styled from "@emotion/styled";

// Local imports
import Loader from "../loaders/Loader";

const PatientsContainer = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 230px)",
  overflowY: "auto",
}));

// Define the columns for the DataGrid
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "firstName", headerName: "First name", width: 120 },
  { field: "lastName", headerName: "Last name", flex: 1 },
  { field: "genderBiological", headerName: "Gender", flex: 1 },
  {
    field: "dob",
    headerName: "DOB",
    renderCell: (params) => {
      return new Date(params.value as string).toLocaleDateString();
    },
    flex: 1,
  },
  { field: "height", headerName: "Height", flex: 1 },
  { field: "weight", headerName: "Weight", flex: 1 },
];

const PatientTableLarge: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPatient = useParams<{ id: string }>().id;

  const {
    data: patientList,
    error: patientListError,
    isLoading: isPatientListLoading,
  } = usePatientGetPatientGetQuery({ limit: 1000 });

  if (isPatientListLoading) {
    return <Loader />;
  }

  if (patientListError) {
    return (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientListError)
          ? String(patientListError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  }
  console.log("patients", patientList);
  const handleCellClick: GridEventListener<"cellClick"> = (params) => {
    if (params.field !== "actions") {
      if (selectedPatient) {
        dispatch(setSelectedPatient(params.id as number));
        navigate(`../${params.id}`, { relative: "path" });
      } else {
        dispatch(setSelectedPatient(params.id as number));
        navigate(`./${params.id}`);
      }
    }
  };
  return (
    <PatientsContainer>
      <DataGridPro
        rows={patientList?.patients || []}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true, // Show the quick filter in the toolbar
          },
        }}
        onCellClick={handleCellClick}
        disableDensitySelector // Disable the density selector
        disableColumnSelector // Disable the column selector
        hideFooter // Hide the footer
        checkboxSelection // Enable checkbox selection
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          }, // Set the pinned columns
          // sorting: { sortModel: [{ field: "event_date", sort: "asc" }] },
        }}
      />
    </PatientsContainer>
  );
};

export default PatientTableLarge;
