// React imports
import React, { useEffect } from "react";
import { useState } from "react";

// Redux imports
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// MUI imports
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRange } from "@mui/x-date-pickers-pro/models";

// API imports
import { usePatientPatientIdTimelinePutPatientPatientIdTimelinePutMutation } from "../../api/jabsCentralApiPrivate";
import { PatientTimeline } from "../../api/jabsCentralApiPrivate";

// Data imports
import exampleDoctors from "../../mockData/exampleDoctors";

// Local imports
import TypeLabel from "./eventComponents/TypeLabel";

interface EditEventFormProps {
  selectedPatient: string;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

const EditEventForm: React.FC<EditEventFormProps> = ({
  selectedPatient,
  open,
  onClose,
  onSave,
}) => {
  // Get the selected event from the redux store
  const selectedEvent = useSelector(
    (state: RootState) => state.selectedEvent.selectedEvent
  );
  // API call to update an event in the timeline of the selected patient
  const [putTimelineEvent] =
    usePatientPatientIdTimelinePutPatientPatientIdTimelinePutMutation();

  // Set the start and end date values for the date range picker
  const startDate = selectedEvent.eventDateTime
    ? new Date(selectedEvent.eventDateTime)
    : new Date();
  const endDate = selectedEvent.eventDateTime
    ? new Date(selectedEvent.eventDateTime)
    : new Date();

  // State to manage the date range values
  const [dateValues, setDateValues] = useState<DateRange<Date>>([
    startDate,
    endDate,
  ]);
  // State to manage the title input
  const [title, setTitle] = useState(selectedEvent.eventTitle);

  const [description, setDescription] = useState(
    selectedEvent.eventDescription
  );
  // State to manage the author input
  const [author, setAuthor] = useState(selectedEvent.author);
  // State to manage the type input
  const [type, setType] = useState(selectedEvent.eventType);

  const [view, setView] = useState("read");

  useEffect(() => {
    setDateValues([
      selectedEvent.eventDateTime
        ? new Date(selectedEvent.eventDateTime)
        : new Date(),
      selectedEvent.eventDateTime
        ? new Date(selectedEvent.eventDateTime)
        : new Date(),
    ]);
    setTitle(selectedEvent.eventTitle);
    setDescription(selectedEvent.eventDescription);
    setAuthor(selectedEvent.author);
    setType(selectedEvent.eventType);
  }, [selectedEvent]);

  let content = null;

  // Function to reset the form fields
  const resetFormFields = () => {
    setDateValues([null, null]);
    setTitle("");
    setDescription("");
    setAuthor("");
    setType("");
  };

  // Function to handle the close event
  const handleClose = () => {
    resetFormFields();
    onClose();
  };

  // Function to handle the save event
  const handleSave = () => {
    console.log("Save event");
    const putEventBody: PatientTimeline = {
      eventId: selectedEvent.eventId,
      eventDateTime: dateValues[0]?.toISOString(),
      eventType: type,
      eventTitle: title,
      eventDescription: description,
      author: author,
      fileUrl: "https://webapp.dev.jabs.ai/", // temp value
    };
    putTimelineEvent({
      patientId: selectedPatient,
      patientTimeline: putEventBody,
    });
    setView("read");
    resetFormFields();
    onSave();
  };

  if (view === "read") {
    console.log("title: ", title);
    content = (
      <>
        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Event Details
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setView("edit")}
            >
              EDIT
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 8 }}>
          <Stack
            direction="row"
            sx={{ width: "100%", justifyContent: "space-between" }}
          >
            <Box>
              <Typography variant="h6" sx={{}}>
                {type && <TypeLabel type={type} />}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{}}>
                {dateValues[0] ? dateValues[0].toLocaleString() : "--"}
              </Typography>
              <Typography variant="h6" sx={{}}>
                {dateValues[1] ? dateValues[1].toLocaleString() : "--"}
              </Typography>
            </Box>
          </Stack>
          <Typography variant="h1" component="div" sx={{ pt: 4 }}>
            {title}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 2 }}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Typography variant="h5" component="div" sx={{}}>
              {author}
            </Typography>
            <Typography variant="h5" component="div" color="GrayText" sx={{}}>
              Role
            </Typography>
          </Stack>
          <Box>
            <Typography variant="h5" sx={{ pt: 7, fontWeight: 700 }}>
              Description
            </Typography>
            <Typography variant="body1" sx={{ pt: 1 }}>
              {description}
            </Typography>
          </Box>
        </Box>
      </>
    );
  } else {
    content = (
      <>
        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Edit Event
            </Typography>
            <Button color="secondary" variant="contained" onClick={handleSave}>
              SAVE
            </Button>
          </Toolbar>
        </AppBar>
        <Stack spacing={4} sx={{ p: 8 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              pt: 4,
            }}
          >
            {/* ************* Event Type ************ */}
            <Box>
              {type && (
                <FormControl sx={{ width: "150px" }}>
                  <InputLabel id="type">Type</InputLabel>

                  <Select
                    id="type"
                    label="Type"
                    value={type}
                    onChange={(event: SelectChangeEvent) => {
                      setType(event.target.value as string);
                    }}
                  >
                    <MenuItem value="Admission">Admission</MenuItem>
                    <MenuItem value="Appointment">Appointment</MenuItem>
                    <MenuItem value="Consultation">Consultation</MenuItem>
                    <MenuItem value="Diagnosis">Diagnosis</MenuItem>
                    <MenuItem value="Discharge">Discharge</MenuItem>
                    <MenuItem value="Meeting">Meeting</MenuItem>
                    <MenuItem value="Note">Note</MenuItem>
                    <MenuItem value="Observation">Observation</MenuItem>
                    <MenuItem value="Procedure">Procedure</MenuItem>
                    <MenuItem value="Treatment">Treatment</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>

            {/* ************* Event Date ************ */}
            {dateValues !== undefined && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimeRangePicker
                  localeText={{ start: "Event start", end: "Event end" }}
                  value={dateValues}
                  onChange={(newValue) => setDateValues(newValue)}
                />
              </LocalizationProvider>
            )}
          </Stack>

          {/* ************* Event Title ************ */}
          {title !== undefined && (
            <TextField
              id="title"
              label="Title"
              variant="filled"
              fullWidth
              value={title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(event.target.value);
              }}
            />
          )}

          {/* ************* Event Author ************ */}
          {author !== undefined && (
            <Autocomplete
              id="author"
              freeSolo
              value={author}
              sx={{ width: "500px" }}
              onChange={(event: any, newValue: string | null) => {
                if (newValue) setAuthor(newValue);
              }}
              options={exampleDoctors.map((option) => option.name)}
              renderInput={(params) => (
                <TextField {...params} label="Author" variant="filled" />
              )}
            />
          )}

          {/* ************* Event Description ************ */}
          {description !== undefined && (
            <TextField
              id="outlined-textarea"
              label="Description"
              placeholder="Description"
              multiline
              fullWidth
              variant="filled"
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(event.target.value);
              }}
            />
          )}
        </Stack>
      </>
    );
  }

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box sx={{ width: "600px" }}>{content}</Box>
    </Drawer>
  );
};

export default EditEventForm;
