// React imports
import React from "react";
import { useState } from "react";

// API imports
import { usePatientPatientIdTimelineGetPatientPatientIdTimelineGetQuery } from "../../api/jabsCentralApiPrivate";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";
import { PatientTimeline } from "../../api/jabsCentralApiPrivate";

// MUI imports
import {
  Box,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ViewList, GridOn } from "@mui/icons-material";
import { AddCircle } from "@mui/icons-material";

// local imports
import Loader from "../../components/loaders/Loader";
import VerticalTimeline from "./VerticalTimeline";
import TableTimeline from "./TableTimeline";
import NewEventForm from "./NewEventForm";
import EditEventForm from "./EditEventForm";

interface ChronologyProps {
  selectedPatient: string;
  title: string;
}

const Chronology: React.FC<ChronologyProps> = ({ selectedPatient, title }) => {
  // State to manage the new event form drawer
  const [openNewEventForm, setOpenNewEventForm] = useState(false);
  const handleNewEventFormOpen = () => {
    setOpenNewEventForm(true);
  };
  const handleNewEventFormClose = () => {
    setOpenNewEventForm(false);
    refetchPatientTimeline();
  };

  const [openEditEventForm, setOpenEditEventForm] = useState(false);
  const handleEditEventFormOpen = () => {
    setOpenEditEventForm(true);
  };
  const handleEditEventFormClose = () => {
    setOpenEditEventForm(false);
  };
  const handleSaveEventFormClose = () => {
    setOpenEditEventForm(false);
    refetchPatientTimeline();
  };

  const viewMode = localStorage.getItem("view"); // Get the view mode from local storage
  const initialView = viewMode ? viewMode : "vertical"; // Set the initial view mode to vertical if not found in local storage
  // State to manage the current view mode (vertical or table)
  const [view, setView] = useState(initialView);

  // Function to handle the view mode change
  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    if (newView !== null) {
      setView(newView);
      localStorage.setItem("view", newView); // Save the view mode to local storage
    }
  };

  const {
    data: patientTimeline,
    error: patientTimelineError,
    isLoading: isPatientTimelineLoading,
    isFetching: isPatientTimelineFetching,
    refetch: refetchPatientTimeline,
  } = usePatientPatientIdTimelineGetPatientPatientIdTimelineGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;

  if (isPatientTimelineLoading) {
    content = <Loader />;
  } else if (isPatientTimelineFetching) {
    content = <Loader />;
  } else if (patientTimelineError) {
    content = (
      <Box>
        Error:{" "}
        {isFetchBaseQueryError(patientTimelineError)
          ? JSON.stringify(patientTimelineError.data)
          : "An unknown error occurred"}
      </Box>
    );
  } else {
    if (!patientTimeline) {
      content = <Box>No patient selected</Box>;
    } else {
      const thisTimeline = patientTimeline as PatientTimeline[];
      content = (
        <Box sx={{ px: 5, py: 5 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2, width: "100%" }}
          >
            <Stack direction="row" alignItems="center">
              <IconButton
                aria-label="new event"
                onClick={handleNewEventFormOpen}
              >
                <AddCircle />
              </IconButton>
              <Typography variant="h4">{title}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" flexWrap="wrap">
              <Typography variant="h6" sx={{ mr: 1 }}>
                <strong>View:</strong>
              </Typography>
              <Box sx={{ width: "75px" }}>
                <Typography variant="h6" sx={{ fontWeight: "300" }}>
                  {view === "vertical" && " Timeline"}
                  {view === "table" && " Table"}
                </Typography>
              </Box>
              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleViewChange}
                aria-label="view mode"
                size="small"
                color="primary"
              >
                <ToggleButton value="vertical" aria-label="vertical view">
                  <ViewList />
                </ToggleButton>
                <ToggleButton value="table" aria-label="table view">
                  <GridOn />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>
          {view === "vertical" && (
            <VerticalTimeline
              timeline={thisTimeline}
              handleOpen={handleEditEventFormOpen}
            />
          )}
          {view === "table" && (
            <TableTimeline
              timeline={thisTimeline}
              handleOpen={handleEditEventFormOpen}
            />
          )}
        </Box>
      );
    }
  }

  return (
    <React.Fragment>
      <NewEventForm
        selectedPatient={selectedPatient}
        open={openNewEventForm}
        onClose={handleNewEventFormClose}
      />
      <EditEventForm
        selectedPatient={selectedPatient}
        open={openEditEventForm}
        onClose={handleEditEventFormClose}
        onSave={handleSaveEventFormClose}
      />

      <Box>{content}</Box>
    </React.Fragment>
  );
};

export default Chronology;
