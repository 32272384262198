// React imports
import React from "react";
import { useState } from "react";

// MUI imports
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRange } from "@mui/x-date-pickers-pro/models";

// API imports
import { usePatientPatientIdTimelinePostPatientPatientIdTimelinePostMutation } from "../../api/jabsCentralApiPrivate";

// Data imports
import exampleDoctors from "../../mockData/exampleDoctors";

interface NewEventFormProps {
  selectedPatient: string;
  open: boolean;
  onClose: () => void;
}

const NewEventForm: React.FC<NewEventFormProps> = ({
  selectedPatient,
  open,
  onClose,
}) => {
  // API call to post a new event to the timeline of the selected patient
  const [postTimelineEvent] =
    usePatientPatientIdTimelinePostPatientPatientIdTimelinePostMutation();

  // State to manage the date range values
  const [dateValues, setDateValues] = useState<DateRange<Date>>([null, null]);
  // State to manage the title input
  const [title, setTitle] = useState("");
  // State to manage the description input
  const [description, setDescription] = useState("");
  // State to manage the author input
  const [author, setAuthor] = useState("");
  // State to manage the type input
  const [type, setType] = useState("");

  // Function to reset the form fields
  const resetFormFields = () => {
    setAuthor("");
    setTitle("");
    setDescription("");
    setType("");
    setDateValues([null, null]);
  };

  // Function to handle the close event
  const handleClose = () => {
    resetFormFields();
    onClose();
  };

  // Function to handle the save event
  const handleSave = () => {
    console.log("Save event");
    const postEventBody = {
      patientId: selectedPatient,
      eventDateTime: dateValues[0]?.toISOString(),
      eventType: type,
      eventTitle: title,
      eventDescription: description,
      author: author,
      fileUrl: "https://webapp.dev.jabs.ai/", // temp value
    };
    postTimelineEvent({
      patientId: selectedPatient,
      patientTimeline: postEventBody,
    });

    // Close the form
    resetFormFields();
    onClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, px: 6 }}>
            New Event Form
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleClose} color="inherit" sx={{ mr: 2 }}>
            CANCEL
          </Button>
          <Button color="secondary" variant="contained" onClick={handleSave}>
            SAVE
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: 600, py: 6, px: 8 }}>
        <Stack useFlexGap spacing={4}>
          {/* Date range picker for event start and end */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimeRangePicker
              localeText={{ start: "Event start", end: "Event end" }}
              value={dateValues}
              onChange={(newValue) => {
                setDateValues(newValue);
              }}
            />
          </LocalizationProvider>
          {/* Text field for title input */}
          <TextField
            id="title"
            label="Title"
            fullWidth
            value={title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTitle(event.target.value);
            }}
          />
          {/* Text field for description input */}
          <TextField
            id="outlined-textarea"
            label="Description"
            placeholder="Description"
            multiline
            fullWidth
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(event.target.value);
            }}
          />

          {/* Autocomplete for author input */}
          <Autocomplete
            id="author"
            freeSolo
            value={author}
            onChange={(event: any, newValue: string | null) => {
              if (newValue) setAuthor(newValue);
            }}
            options={exampleDoctors.map((option) => option.name)}
            renderInput={(params) => <TextField {...params} label="Author" />}
          />

          {/* Select field for type input */}
          <FormControl fullWidth>
            <InputLabel id="type" sx={{}}>
              Type
            </InputLabel>

            <Select
              id="type"
              label="Type"
              value={type}
              onChange={(event: SelectChangeEvent) => {
                setType(event.target.value as string);
              }}
            >
              <MenuItem value="Admission">Admission</MenuItem>
              <MenuItem value="Appointment">Appointment</MenuItem>
              <MenuItem value="Consultation">Consultation</MenuItem>
              <MenuItem value="Diagnosis">Diagnosis</MenuItem>
              <MenuItem value="Discharge">Discharge</MenuItem>
              <MenuItem value="Meeting">Meeting</MenuItem>
              <MenuItem value="Note">Note</MenuItem>
              <MenuItem value="Observation">Observation</MenuItem>
              <MenuItem value="Procedure">Procedure</MenuItem>
              <MenuItem value="Treatment">Treatment</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default NewEventForm;
