import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../loaders/Loader";
import { DeleteForever } from "@mui/icons-material";
import { RootState } from "../../../redux/store";
import { setSelectedFileItem } from "../../../redux/slices/fileSlice";
import {
  usePatientPatientIdFilesGetPatientPatientIdFilesGetQuery,
  useFilesFileIdDeleteFilesFileIdDeleteMutation,
} from "../../../api/jabsCentralApiPrivate";
import { isFetchBaseQueryError } from "../../../utils/isFetchBaseQueryError";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridEventListener,
} from "@mui/x-data-grid";
import { Box, Dialog, Typography } from "@mui/material";

// Interface defining the props for FileViewer component
export interface FileViewerProps {
  fileUrl: string;
  fileType: string;
  open: boolean;
  onClose: () => void;
}

// FileViewer component definition
function FileViewer({ fileUrl, fileType, open, onClose }: FileViewerProps) {
  // Function to handle closing the viewer
  const handleClose = () => {
    onClose();
  };

  // Variable to hold the content to be displayed in the viewer
  let viewerContent = null;

  // Display image if fileType includes "image"
  if (fileType.includes("image")) {
    viewerContent = (
      <img src={fileUrl} alt="File Viewer" onClick={() => handleClose()} />
    );
  }
  // Display PDF if fileType includes "pdf"
  else if (fileType.includes("pdf")) {
    viewerContent = (
      <React.Fragment>
        <Box onClick={() => handleClose()}>
          <embed
            src={fileUrl}
            aria-label="pdf viewer"
            type="application/pdf"
          ></embed>
          <Typography variant="body1" sx={{ p: 4 }}>
            Click to close
          </Typography>
        </Box>
      </React.Fragment>
    );
  }
  // Close the viewer for other file types
  else {
    handleClose();
  }

  // Return the viewer content wrapped in a Dialog component
  return <Dialog open={open}>{viewerContent}</Dialog>;
}

// Column definitions for the DataGrid
const columns: GridColDef[] = [
  { field: "filename", headerName: "Name", flex: 1, minWidth: 150 },
  { field: "status", headerName: "Status", width: 120 },
  { field: "filetype", headerName: "Type", flex: 0.5, minWidth: 150 },
  {
    field: "updateDate",
    headerName: "Date Updated",
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      return new Date(params.value as string).toLocaleDateString();
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    width: 60,
    align: "center",
    renderCell: (params: GridRenderCellParams) => {
      return <DeleteForever />;
    },
  },
];

// FileList component definition
const FileList: React.FC = () => {
  // State to manage the viewer's open status, presigned URL, and file type
  const [viewerOpen, setViewerOpen] = useState(false);
  const [presignedUrl, setpresignedUrl] = useState("");
  const [fileType, setFileType] = useState("");

  // Function to handle closing the viewer
  const handleClose = () => {
    setViewerOpen(false);
  };

  // Redux hooks to dispatch actions and select state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );
  const newFileUrl = useSelector(
    (state: RootState) => state.newFileUrl.newFileUrl
  );

  // Mutation hook for deleting a file
  const [deleteFile] = useFilesFileIdDeleteFilesFileIdDeleteMutation();

  // Query hook for fetching patient files
  const {
    data: patientFiles,
    error: patientFilesError,
    isLoading: isPatientFilesLoading,
    refetch: refetchPatientFiles,
  } = usePatientPatientIdFilesGetPatientPatientIdFilesGetQuery(
    {
      patientId: selectedPatient,
    },
    { pollingInterval: 30000 }
  );

  // Effect to refetch patient files when newFileUrl changes
  useEffect(() => {
    refetchPatientFiles();
  }, [newFileUrl, refetchPatientFiles]);

  // Handler for cell click events in the DataGrid
  const handleCellClick: GridEventListener<"cellClick"> = async (params) => {
    dispatch(setSelectedFileItem(params.row.fileId));
    console.log("Cell clicked: ", params);
    if (params.field !== "delete") {
      if (params.row.filetype !== "application/dicom") {
        setViewerOpen(true);
        setpresignedUrl(params.row.presignedUrl);
        setFileType(params.row.filetype);
      } else {
        navigate(`/page/dicom-viewer`);
      }
    } else {
      const deleteResponse = await deleteFile({ fileId: params.row.fileId });
      console.log("Delete response: ", deleteResponse);
      refetchPatientFiles();
    }
  };

  // Variable to hold the content to be rendered
  let content = null;

  // Display loader if patient files are loading
  if (isPatientFilesLoading) {
    console.log("Loading patient files...");
    content = <Loader />;
  }
  // Display error message if there is an error
  else if (patientFilesError) {
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientFilesError)
          ? String(patientFilesError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  }
  // Display the DataGrid with patient files if data is successfully fetched
  else {
    content = (
      <React.Fragment>
        <DataGrid
          rows={patientFiles?.files || []}
          columns={columns}
          getRowId={(row) => row.fileId}
          autoHeight
          onCellClick={handleCellClick}
        />
      </React.Fragment>
    );
  }

  // Return the content and the FileViewer component
  return (
    <React.Fragment>
      {content}
      <FileViewer
        fileUrl={presignedUrl}
        fileType={fileType}
        open={viewerOpen}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

// Exporting the FileList component as default
export default FileList;
