import React from "react";
import { Helmet } from "react-helmet-async";
import { Button, Grid, IconButton, Stack } from "@mui/material";
import { AddCircle, GridView, TableRows } from "@mui/icons-material";
// import styled from "@emotion/styled";

import TLPagebar from "../../../components/navbar/appBar/TLPageBar";
import CaseList from "../../../components/caseList/CaseList";
// import CaseListTitle from "../../../components/caseList/CaseListTitle";

// import caseData from "../../../mockData/caseData";

const ToolbarRight = () => {
  return (
    <React.Fragment>
      <Stack direction="row">
        <IconButton color="primary" aria-label="card view">
          <GridView />
        </IconButton>
        <IconButton aria-label="table view">
          <TableRows />
        </IconButton>

        <Button
          sx={{ ml: 8, mr: 3 }}
          variant="contained"
          color="primary"
          startIcon={<AddCircle />}
        >
          Create Case
        </Button>
      </Stack>
    </React.Fragment>
  );
};

function CaseDashboard() {
  return (
    <React.Fragment>
      <Helmet title="Api Test Page" />
      <TLPagebar
        title="Case Dashboard"
        rightTools={<ToolbarRight />}
        breakpointRight="sm"
      />
      {/* <CaseListTitle /> */}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
          <CaseList />
        </Grid>
        <Grid item></Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CaseDashboard;
