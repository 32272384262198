import React, { useState } from "react";
import { Box, Drawer, Grid, IconButton as MuiIconButton } from "@mui/material";
import { Person } from "@mui/icons-material";

import PatientTable from "./PatientTable";

const PatientDrawer: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <MuiIconButton onClick={handleToggle}>
        <Person />
      </MuiIconButton>
      <Drawer anchor="right" open={open} onClose={handleToggle}>
        <Grid container sx={{ padding: "20px", width: "490px" }}>
          <Grid item>
            <PatientTable />
          </Grid>
        </Grid>
      </Drawer>
    </Box>
  );
};

export default PatientDrawer;
