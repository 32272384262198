import React from "react";
import { Helmet } from "react-helmet-async";

function Blank() {
  return (
    <React.Fragment>
      <Helmet title="Blank" />
    </React.Fragment>
  );
}

export default Blank;
