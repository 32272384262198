import styled from "@emotion/styled";

import VitruvianBaseObese from "./assets/vitruvian-male-obese.png";
import LungLayerAmber from "./LungLayerAmber";
import HeartLayerDanger from "./HeartDangerLayer";
import HipArea from "./HipArea";
import TLR from "./TLR";

import "./assets/animation.css";

interface VitruvianProps {
  width?: string;
}

const VitruvianSekaniAbara: React.FC<VitruvianProps> = ({ width }) => {
  const BaseLayer = styled("div")`
    position: relative;
    width: ${width};
  `;
  return (
    <BaseLayer>
      <img src={VitruvianBaseObese} alt="Vitruvian" width="100%" />
      <LungLayerAmber />
      <HeartLayerDanger />
      <HipArea />
      <TLR />
    </BaseLayer>
  );
};

export default VitruvianSekaniAbara;
