import React from "react";
import ListApprover from "./listApprover";
import { useState } from "react";

// Interface defining the props for MedicationsList component
interface MedicationsListProps {
  existingItems: any[];
  proposedItems: any[];
}

// MedicationsList component definition
const MedicationsList = ({
  existingItems,
  proposedItems,
}: MedicationsListProps) => {
  // State to manage existing medications
  const [existingMedications, setExistingMedications] = useState(existingItems);
  // State to manage proposed medications
  const [proposedMedications, setProposedMedications] = useState(proposedItems);

  // Handler to update existing medications
  const existingHandler = (items: any[]) => {
    setExistingMedications(items);
  };

  // Handler to update proposed medications
  const proposedHandler = (items: any[]) => {
    setProposedMedications(items);
  };

  return (
    <div>
      {/* ListApprover component to manage and display medications */}
      <ListApprover
        existingItems={existingMedications}
        existingHandler={existingHandler}
        proposedItems={proposedMedications}
        proposedHandler={proposedHandler}
        title="Medications"
      />
    </div>
  );
};

// Exporting the MedicationsList component as default
export default MedicationsList;
