import React from "react";
import { Card, Divider, Typography } from "@mui/material";
interface CaseCardProps {
  patient: any;
}

const CaseCard = (patient: CaseCardProps) => {
  return (
    <Card
      sx={{ p: 5, borderRadius: 2, boxShadow: 2, border: "1px solid #E2E4E7" }}
    >
      <Typography variant="h4">
        {patient.patient.plaintiff_information.full_name}
      </Typography>
      <Typography variant="caption">
        DOB: {patient.patient.plaintiff_information.date_of_birth}
        <br />
        MRN: {patient.patient.plaintiff_information.social_security_number}
      </Typography>
      <Divider sx={{ my: 5 }} />

      <Typography variant="h6" sx={{ mb: 2 }}>
        {patient.patient.incident_information.allegations_of_malpractice}
      </Typography>
      <Typography variant="body1">
        {patient.patient.incident_information.description_of_malpractice}
      </Typography>
    </Card>
  );
};

export default CaseCard;
