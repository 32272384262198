import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Loader from "../loaders/Loader";

import { usePatientPatientIdSummaryGetPatientPatientIdSummaryGetQuery } from "../../api/jabsCentralApiPrivate";

const Wordwrap = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const GetSummary: React.FC = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const {
    data: patientSummary,
    error: patientSummaryError,
    isLoading: isPatientSummaryLoading,
    isFetching: isPatientSummaryFetching,
  } = usePatientPatientIdSummaryGetPatientPatientIdSummaryGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;

  if (isPatientSummaryLoading) {
    content = <Loader />;
  } else if (isPatientSummaryFetching) {
    content = <Loader />;
  } else if (patientSummaryError) {
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientSummaryError)
          ? JSON.stringify(patientSummaryError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientSummary) {
      content = <div>Select a patient to view raw API data</div>;
    } else {
      content = (
        <div>
          <Wordwrap>{JSON.stringify(patientSummary, null, 2)}</Wordwrap>
        </div>
      );
    }
  }

  return (
    <div>
      <Typography variant="h4">GET /patient/[patientId]/summary</Typography>
      {content}
    </div>
  );
};

export default GetSummary;
