// react imports
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
// import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";

// material ui imports
import { Button } from "@mui/material";
// import styled from "@emotion/styled";

// api imports
import { usePatientPatientIdTimelinePostPatientPatientIdTimelinePostMutation } from "../../api/jabsCentralApiPrivate";

const PostTimeline = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const testPostBody = {
    patientId: selectedPatient,
    eventDateTime: "2024-11-06T08:40:53.971Z",
    eventType: "string",
    eventTitle: "string",
    eventDescription: "string",
    fileId: "172",
    fileDateTime: null,
    fileUrl:
      "https://s3.eu-west-2.amazonaws.com/files.dev.jabs.ai/1/Patient_1_Jane_Doe_ChestCT.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA54YEGVR7XCAOECQM%2F20241119%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20241119T144814Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEN%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMiJIMEYCIQC5koSFvvy%2BJG%2BQi%2BPTvFzyNnxYpvBKdsKaQGOMqfTTwQIhAOBmKVKg5RJbXjUkE%2FnAOQKCcHiJfUlI0CArr%2BKnr0SFKpUDCHgQABoMOTU1MTAyMTc4NDMxIgzsLDXrN8CLDZt%2B0rsq8gJzwNRFJOiXvWvINRaifdpo3W0hs%2FZDTLrc2oxgRX1Ttk49YWxD8C%2BGUa9svYBEV%2Fmi3TMYbQQ11B5Ku8BCkzMbSyRujo8NcYcQEhoctCYtFtSstg%2F4KU8coEdKIcs450gT7cwgoXN6j89pSCO%2Fa5t9YhhMHEgVbmL6s8JBYDnB3kv40J1hpMmtlTVDRYYqzp4vF5m5GVF%2FsiAQfsqup1vm1blpYPMBmFB29CAwUxZpdNoHTO3DeXciXB41gXCTmYKSzKYz9r3uoKR4F532d%2FeTrtG5pY%2FcoIh%2B1Uc2acRXj6Eh2zOkWESC7KkLCMsJi76%2BERYgSvn5J6kVuYoLfgWZCEZ46Z8abvim1nibfROTSF72dNCUavJJS2xTT6sk9sC5Ywxvt21nQ9%2BnfR3OzIGOwXWCtyQGxF0ovdpG2t1zr1gQ6REyxH8Mb6njl7Epy6NNuGm%2BLl4SPjoa8PN7pMNK1GNxNi7IQOhPQbG4fngeKRYFMKPK8rkGOpwB9ud2uUbb6WsTh3gJigQQ3ZsKDv0ifVouGJLSg6hO9xh8WO0AZ%2FAm%2FLHP6%2FqgOLDtqYUQ3tEJi9UH2rAvPmGRIxyI32z65vxh1hXYiTZQO4umPZpXTYcqQw6h2Bp0z9diOfFlprBRXpkv8NLQO9d2JITCC8%2FGlqP16TiGWd%2BhER5i6dUjjELgZderf8cOLklygcConXBZne8JF2nr&X-Amz-Signature=a59bc7b91deea558ac10c6f72819e35ce855e150bbcecc5672886421ea26226e",
    author: "string",
    status: "string",
  };

  const [postTimelineEvent] =
    usePatientPatientIdTimelinePostPatientPatientIdTimelinePostMutation();

  const handlePostTimelineEvent = () => {
    postTimelineEvent({
      patientId: selectedPatient,
      patientTimeline: testPostBody,
    });
  };

  return (
    <div>
      <Button
        variant="contained"
        size="large"
        onClick={handlePostTimelineEvent}
      >
        POST /patient/[patientId]/timeline
      </Button>
    </div>
  );
};

export default PostTimeline;
