import React from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import ThemeDrawer from "../themeControl/ThemeDrawer";

interface ThemeDrawerControlProps {
  children?: React.ReactNode;
}

const ThemeDrawerControl = ({ children }: ThemeDrawerControlProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = (newOpen: boolean) => {
    setOpenDrawer(newOpen);
    console.log("newOpen", newOpen);
  };
  return (
    <>
      <Box onClick={() => toggleDrawer(true)}>{children}</Box>
      <ThemeDrawer
        open={openDrawer}
        toggleHandler={() => toggleDrawer(false)}
      />
    </>
  );
};

export default ThemeDrawerControl;
