import React, { useState } from "react";
import {
  usePatientGetPatientGetQuery,
  usePatientPatientIdGetPatientPatientIdGetQuery,
} from "../../api/jabsCentralApiPrivate"; // Adjust the import path accordingly
import { skipToken } from "@reduxjs/toolkit/query/react";

const DataFetcher: React.FC = () => {
  const {
    data: patients,
    error: patientsError,
    isLoading: isPatientsLoading,
  } = usePatientGetPatientGetQuery({ limit: 10 });
  const [selectedPatientId, setSelectedPatientId] = useState<string | null>(
    null
  );
  const {
    data: patient,
    error: patientError,
    isLoading: isPatientLoading,
  } = usePatientPatientIdGetPatientPatientIdGetQuery(
    selectedPatientId ? { patientId: selectedPatientId } : skipToken
  );

  if (isPatientsLoading) {
    return <div>Loading patients...</div>;
  }

  if (patientsError) {
    return (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientsError)
          ? String(patientsError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  }
  console.log("patients", patients);
  return (
    <div>
      <h1>Patients</h1>
      <ul>
        {patients?.patients?.map((patient) => (
          <li key={patient.id} onClick={() => setSelectedPatientId(patient.id)}>
            {patient.firstName} {patient.lastName}
          </li>
        ))}
      </ul>
      {selectedPatientId && (
        <div>
          {isPatientLoading ? (
            <div>Loading patient details...</div>
          ) : patientError ? (
            <div>
              Error:{" "}
              {isFetchBaseQueryError(patientError)
                ? (patientError.data as React.ReactNode) // Add type assertion
                : "An unknown error occurred"}
            </div>
          ) : (
            patient && (
              <div>
                <h2>Patient Details</h2>
                <p>ID: {patient.id}</p>
                <p>
                  Name: {patient.firstName} {patient.lastName}
                </p>
                {/* Add more details as needed */}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

const isFetchBaseQueryError = (
  error: any
): error is { status: number; data: unknown } => {
  return typeof error === "object" && "status" in error && "data" in error;
};

export default DataFetcher;
