import React from "react";
import { Box, Grid } from "@mui/material";

import caseData from "../../mockData/caseData";

import CaseCard from "./CaseCard";

const CaseList = () => {
  return (
    <Box sx={{ px: 5, py: 3 }}>
      <Grid container spacing={4}>
        {caseData.map((item: any, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <CaseCard patient={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CaseList;
