import React from "react";
import { Box, Typography } from "@mui/material";

// Interface for the component props
interface BasicDetailsProps {
  patient: {
    id: string | null | undefined;
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    dob: string | null | undefined;
    age: string | null | undefined;
    location: string | null | undefined;
    height: string | null | undefined;
    weight: string | null | undefined;
    gender: string | null | undefined;
  };
}

// Component to display basic details of a patient
const BasicDetails = ({
  patient: {
    id,
    firstName,
    lastName,
    dob,
    age,
    location,
    height,
    weight,
    gender,
  },
}: BasicDetailsProps) => {
  // Determine the gender to display
  var renderGender = "";
  if (gender === "M") {
    renderGender = "Male";
  } else if (gender === "F") {
    renderGender = "Female";
  }

  return (
    <Box ml={2}>
      {/* Display patient's name */}
      <Typography mb={3} variant="h4">
        {firstName} {lastName}
      </Typography>
      {/* Display patient's NHS ID */}
      <Typography mb={1} variant="body1">
        <strong>NHS:</strong> {id}
      </Typography>
      {/* Display patient's date of birth */}
      <Typography mb={1} variant="body1">
        <strong>DOB:</strong> {dob}
      </Typography>
      {/* Display patient's location */}
      <Typography mb={1} variant="body1">
        <strong>Location:</strong> {location}
      </Typography>
      {/* Display patient's gender */}
      <Typography mb={1} variant="body1">
        <strong>Gender:</strong> {renderGender}
      </Typography>
      {/* Display patient's height */}
      <Typography mb={1} variant="body1">
        <strong>Height:</strong> {height}
      </Typography>
      {/* Display patient's weight */}
      <Typography mb={1} variant="body1">
        <strong>Weight:</strong> {weight}
      </Typography>
      {/* Display patient's age */}
      <Typography variant="body1">
        <strong>Age:</strong> {age}
      </Typography>
    </Box>
  );
};

export default BasicDetails;
