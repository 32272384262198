// React imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// Redux imports
import { useDispatch } from "react-redux";
import { setSelectedPatient } from "../../redux/slices/selectedPatientSlice";

// API imports
import { usePatientGetPatientGetQuery } from "../../api/jabsCentralApiPrivate";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";

// MUI imports
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";

// Local imports
import Loader from "../loaders/Loader";

// Define the columns for the DataGrid
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "firstName", headerName: "First name", width: 100 },
  { field: "lastName", headerName: "Last name", width: 100 },
  {
    field: "dob",
    headerName: "DOB",
    renderCell: (params: GridRenderCellParams) => {
      return new Date(params.value as string).toLocaleDateString();
    },
    width: 150,
  },
];

const PatientTable: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPatient = useParams<{ id: string }>().id;

  const {
    data: patients,
    error: patientsError,
    isLoading: isPatientsLoading,
  } = usePatientGetPatientGetQuery({ limit: 1000 });

  if (isPatientsLoading) {
    return <Loader />;
  }

  if (patientsError) {
    return (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientsError)
          ? String(patientsError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  }
  console.log("patients", patients);
  const handleCellClick: GridEventListener<"cellClick"> = (params) => {
    if (params.field !== "actions") {
      if (selectedPatient) {
        dispatch(setSelectedPatient(params.id as number));
        navigate(`../${params.id}`, { relative: "path" });
      } else {
        dispatch(setSelectedPatient(params.id as number));
        navigate(`./${params.id}`);
      }
    }
  };
  return (
    <div>
      <DataGrid
        rows={patients?.patients || []}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        disableColumnFilter
        disableDensitySelector
        disableColumnSelector
        pageSizeOptions={[5, 10, 25, 100]}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        autoHeight
        onCellClick={handleCellClick}
      />
    </div>
  );
};

export default PatientTable;
