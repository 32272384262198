import React from "react";
import { Helmet } from "react-helmet-async";
import SummaryTest from "../../components/AiApproval/SummaryTest";

function TextTest() {
  return (
    <React.Fragment>
      <Helmet title="Text Test" />
      <SummaryTest />
    </React.Fragment>
  );
}

export default TextTest;
