// React imports
import React from "react";
import { Helmet } from "react-helmet-async";

// Redux imports
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// API imports
import { usePatientPatientIdGetPatientPatientIdGetQuery } from "../../api/jabsCentralApiPrivate";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";

// MUI imports
import { Box, Breadcrumbs, Card, Link, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import PatientDrawer from "../../components/patientSelection/PatientDrawer";
import TLPagebar from "../../components/navbar/appBar/TLPageBar";
import Loader from "../../components/loaders/Loader";
import Chronology from "../../components/chronology/Chronology";

const PageContent = styled(Box)`
  padding: 8px 24px 8px 24px;
`;

const ContentCard = styled(Card)`
  height: calc(100vh - 180px);
  overflow-y: auto;
`;

function SelectedPatientView() {
  //Get the selected patient from the Redux store
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const {
    data: patientDetails,
    error: patientDetailsError,
    isLoading: isPatientDetailsLoading,
    isFetching: isPatientDetailsFetching,
  } = usePatientPatientIdGetPatientPatientIdGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;

  // If the patient details are loading, show the Loader component
  if (isPatientDetailsLoading) {
    content = <Loader />;
    console.log("Loading patient details...");
  } else if (isPatientDetailsFetching) {
    content = <Loader />;
    console.log("Fetching patient details...");
  } else if (patientDetailsError) {
    console.error("Error fetching patient details: ", patientDetailsError);
    // Show error message if there was an error fetching the data
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientDetailsError)
          ? String(patientDetailsError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientDetails || selectedPatient === "-1") {
      console.log("No patient details available.");
      // Show prompt if no patient details are available
      content = (
        <React.Fragment>
          <Helmet title="Patient View" />
          <TLPagebar title="Patient View" rightTools={<PatientDrawer />} />
          <PageContent>
            <ContentCard>
              {/* <ChronologyFullPage title="Patient Chronology" /> */}
            </ContentCard>
          </PageContent>
        </React.Fragment>
      );
    } else {
      // Extract relevant patient details
      const thisPatient = {
        id: patientDetails.id,
        firstName: patientDetails.firstName,
        lastName: patientDetails.lastName,
        dob: patientDetails.dob,
        age: patientDetails.age,
        location: patientDetails.location,
        height: patientDetails.height,
        weight: patientDetails.weight,
      };
      const patientName: string = `${thisPatient.firstName} ${thisPatient.lastName}`; // Full name of the patient
      content = (
        <React.Fragment>
          <Helmet title={patientName} />
          <TLPagebar title={patientName} rightTools={<PatientDrawer />} />
          <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 6, mt: 2 }}>
            <Link underline="hover" href="/">
              Home
            </Link>
            <Link href="/admin/patient-view">Patients</Link>
            <Typography>{patientName}</Typography>
          </Breadcrumbs>

          <PageContent>
            <ContentCard>
              <Chronology
                selectedPatient={selectedPatient}
                title="Patient Chronology"
              />
            </ContentCard>
          </PageContent>
        </React.Fragment>
      );
    }
  }

  return content;
}

export default SelectedPatientView;
