import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Loader from "../loaders/Loader";

import { usePatientPatientIdReportsGetPatientPatientIdReportsGetQuery } from "../../api/jabsCentralApiPrivate";

const Wordwrap = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const GetReports: React.FC = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const {
    data: patientReports,
    error: patientReportsError,
    isLoading: isPatientReportsLoading,
    isFetching: isPatientReportsFetching,
  } = usePatientPatientIdReportsGetPatientPatientIdReportsGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;

  if (isPatientReportsLoading) {
    content = <Loader />;
  } else if (isPatientReportsFetching) {
    content = <Loader />;
  } else if (patientReportsError) {
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientReportsError)
          ? JSON.stringify(patientReportsError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientReports) {
      content = <div>Select a patient to view raw API data</div>;
    } else {
      content = (
        <div>
          <Wordwrap>{JSON.stringify(patientReports, null, 2)}</Wordwrap>
        </div>
      );
    }
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        GET /patient/[patientId]/reports
      </Typography>
      {content}
    </div>
  );
};

export default GetReports;
