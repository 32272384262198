import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AiIcon } from "../icons/TLSvgIcons";

const ThinlayerContainer = styled(Box)`
  border: 1px solid #ffcea2;
  background: #fffbf7;
  color: black;
  padding: 0 0 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 5px;
`;

const ThinlayerBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThinlayerContainer>
      <Box>{children}</Box>
      <Typography align="right">
        <IconButton
          aria-label="functions"
          size="small"
          color="secondary"
          sx={{ marginLeft: "auto" }}
        >
          <AiIcon />
        </IconButton>
      </Typography>
    </ThinlayerContainer>
  );
};

export default ThinlayerBox;
