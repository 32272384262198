const eventDataSekari = [
  {
    id: 1,
    document_id: 1,
    patient_id: "1",
    event_type: "note",
    event_date: "2020-01-20T08:14:00Z",
    event_end: "2020-01-30T08:14:00Z",
    event_title: "Hospitalisation (NSTEMI)",
    event_abstract:
      "NSTEMI in Left Anterior Descending coronary distribution with preserved LVEF (50%); successfully stented without complications.",
    title: "title",
    author: "Dr. Eric Ries",
    document_date: "2020-01-20T08:14:00Z",
    link_to_pdf: "https://www.example.com",
  },
  {
    id: 2,
    document_id: 2,
    patient_id: "1",
    event_type: "note",
    event_date: "2024-04-15T08:13:00Z",
    event_end: "2024-04-15T08:13:00Z",
    event_title: "GP Visit & Referral",
    event_abstract:
      "Regular annual checkup, noteworthy for right hip discomfort limiting his ability to exercise, and making climbing up to his second story flat difficult. Referral to orthopaedics.",
    title: "title",
    author: "Dr. Nick Taylor",
    author_role: "G.P.",
    document_date: "2024-04-15T08:00:00Z",
    link_to_pdf: "https://www.example.com",
  },
  {
    id: 3,
    document_id: 3,
    patient_id: "1",
    event_type: "observation",
    event_date: "2024-04-29T13:00:00Z",
    event_end: "2024-04-29T13:00:00Z",
    event_title: "Orthopaedic Consult",
    event_abstract:
      "Hip series shows degenerative changes to R hip consistent with osteoarthritis. Candidate for hip arthroplasty if he can be cleared from a CV perspective.",
    title: "title",
    author: "Mr Cash",
    author_role: "Orthopaedic Surgeon",
    document_date: "2024-04-29T08:13:00Z",
    link_to_pdf: "https://www.example.com",
  },
  {
    id: 4,
    document_id: 4,
    patient_id: "1",
    event_type: "observation",
    event_date: "2024-04-29T08:13:00Z",
    event_end: "2024-04-29T08:13:00Z",
    event_title: "Hip X-Rays",
    event_abstract: "Osteoarthritic changes, R hip.",
    title: "title",
    author: "Dr. John Ferrier",
    author_role: "Staff Radiologist",
    document_date: "2024-04-29T13:00:00Z",
    link_to_pdf: "https://www.example.com",
  },
  {
    id: 5,
    document_id: 5,
    patient_id: "1",
    event_type: "observation",
    event_date: "2024-05-10T13:00:00Z",
    event_end: "2024-05-10T13:00:00Z",
    event_title: "Cardiac PET scan",
    event_abstract:
      "Pharmacologic cardiac stress test/PET scan: no focal ischemic abnormalities.",
    title: "title",
    author: "Dr Mark Appleby",
    author_role: "Cardiologist",
    document_date: "2024-05-10T08:13:00Z",
    link_to_pdf: "https://www.example.com",
  },
  {
    id: 6,
    document_id: 6,
    patient_id: "1",
    event_type: "observation",
    event_date: "2024-05-10T08:13:00Z",
    event_end: "2024-05-10T08:13:00Z",
    event_title: "EKG",
    event_abstract:
      "Normal sinus rhythm; old anterior wall MI; no apparent acute ischemia.",
    title: "title",
    author: "Dr Mark Appleby",
    author_role: "Cardiologist",
    document_date: "2024-05-10T08:13:00Z",
    link_to_pdf: "https://www.example.com",
  },
];

export default eventDataSekari;
