import React from "react";
import { withTheme } from "@emotion/react";

// import { RamsayIcon } from "../icons/TLSvgIcons";
import { MenuOpen } from "@mui/icons-material";

// import NavbarCreate from "./NavbarCreate";

import { Box, Fab } from "@mui/material";

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
  breakpoint?: string;
};

const NavToggle: React.FC<NavbarProps> = ({ onDrawerToggle, breakpoint }) => {
  const display = breakpoint
    ? { xs: "block", [breakpoint]: "none" }
    : { xs: "block" };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: display,
          position: "fixed",
          top: "12px",
          left: "16px",
          zIndex: 1200,
        }}
      >
        <Fab
          color="primary"
          aria-label="Open drawer"
          onClick={onDrawerToggle}
          size="small"
          sx={{
            boxShadow: 0,
          }}
        >
          <MenuOpen />
        </Fab>
      </Box>
    </React.Fragment>
  );
};

export default withTheme(NavToggle);
