// React imports
import React from "react";

// Redux imports
import { useDispatch } from "react-redux";
import { changeSelectedEvent } from "../../redux/slices/selectedEventSlice";

// MUI imports
import { Box } from "@mui/material";
import styled from "@emotion/styled";

// API imports
import { PatientTimeline } from "../../api/jabsCentralApiPrivate";

// Component imports
import ChronologyEvent from "./verticalComponents/ChronologyEvent";

// Styled component for the timeline container
const TimelineContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "20px",
  height: "calc(100vh - 260px)",
  overflowY: "auto",
}));

// Interface defining the props for VerticalTimeline component
interface VerticalTimelineProps {
  timeline: PatientTimeline[];
  handleOpen: () => void;
}

const VerticalTimeline = ({ timeline, handleOpen }: VerticalTimelineProps) => {
  // Redux dispatch function
  const dispatch = useDispatch();

  const handleEventClick = (event: PatientTimeline) => {
    dispatch(changeSelectedEvent(event));
    handleOpen();
  };

  return (
    <TimelineContainer>
      {timeline.map((event: PatientTimeline, index) => {
        // Determine the line type for the event
        var lineType = "middle";

        if (timeline.length === 1) {
          lineType = "single";
        } else if (index === 0) {
          lineType = "top";
        } else if (index === timeline.length - 1) {
          lineType = "bottom";
        }

        // Render the event with the appropriate line type
        return (
          <Box onClick={() => handleEventClick(event)}>
            <ChronologyEvent key={index} lineType={lineType} event={event} />
          </Box>
        );
      })}
    </TimelineContainer>
  );
};

export default VerticalTimeline;
