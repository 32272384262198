import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NewFileUrlState {
  newFileUrl: string | null;
}

const initialState: NewFileUrlState = {
  newFileUrl: null,
};

const newFileUrlSlice = createSlice({
  name: "newFileUrl",
  initialState,
  reducers: {
    setNewFileUrl(state, action: PayloadAction<string>) {
      state.newFileUrl = action.payload;
    },
    clearNewFileUrl(state) {
      state.newFileUrl = null;
    },
  },
});

export const { setNewFileUrl, clearNewFileUrl } = newFileUrlSlice.actions;
export default newFileUrlSlice.reducer;
