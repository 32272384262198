import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Loader from "../loaders/Loader";

import { usePatientPatientIdVitruvianGetPatientPatientIdVitruvianGetQuery } from "../../api/jabsCentralApiPrivate";

const Wordwrap = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const GetVitruvian: React.FC = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const {
    data: patientVitruvian,
    error: patientVitruvianError,
    isLoading: isPatientVitruvianLoading,
    isFetching: isPatientVitruvianFetching,
  } = usePatientPatientIdVitruvianGetPatientPatientIdVitruvianGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;

  if (isPatientVitruvianLoading) {
    content = <Loader />;
  } else if (isPatientVitruvianFetching) {
    content = <Loader />;
  } else if (patientVitruvianError) {
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientVitruvianError)
          ? JSON.stringify(patientVitruvianError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientVitruvian) {
      content = <div>Select a patient to view raw API data</div>;
    } else {
      content = (
        <div>
          <Wordwrap>{JSON.stringify(patientVitruvian, null, 2)}</Wordwrap>
        </div>
      );
    }
  }

  return (
    <div>
      <Typography variant="h4">GET /patient/[patientId]/vitruvian</Typography>
      {content}
    </div>
  );
};

export default GetVitruvian;
