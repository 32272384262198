import React from "react";
import ListApprover from "./listApprover";
import { useState } from "react";

// Interface for the component props
interface AllergiesListProps {
  existingItems: any[];
  proposedItems: any[];
}

// Component to display and manage the list of allergies
const AllergiesList = ({
  existingItems,
  proposedItems,
}: AllergiesListProps) => {
  // State to manage existing allergies
  const [existingAllergies, setExistingAllergies] = useState(existingItems);
  // State to manage proposed allergies
  const [proposedAllergies, setProposedAllergies] = useState(proposedItems);

  // Handler to update existing allergies
  const existingHandler = (items: any[]) => {
    setExistingAllergies(items);
  };

  // Handler to update proposed allergies
  const proposedHandler = (items: any[]) => {
    setProposedAllergies(items);
  };

  return (
    <div>
      {/* Component to approve or reject items in the list */}
      <ListApprover
        existingItems={existingAllergies}
        existingHandler={existingHandler}
        proposedItems={proposedAllergies}
        proposedHandler={proposedHandler}
        title="Allergies"
      />
    </div>
  );
};

export default AllergiesList;
