import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Importing hooks from react-redux
import { setNewFileUrl } from "../../../redux/slices/newFileUrlSlice"; // Importing action to set new file URL

import { RootState } from "../../../redux/store"; // Importing RootState type from the redux store
import {
  FilesPutFilesPutApiArg,
  useFilesPutFilesPutMutation,
} from "../../../api/jabsCentralApiPrivate"; // Importing API argument type and mutation hook
import axios from "axios"; // Importing axios for HTTP requests
import { Button, Stack, Typography } from "@mui/material"; // Importing MUI components
import { AttachFile, CloudUpload } from "@mui/icons-material"; // Importing MUI icons
import { styled } from "@mui/system"; // Importing styled from MUI system

// Styled component for visually hidden input
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// SingleFileUploader component definition
const SingleFileUploader = () => {
  const dispatch = useDispatch(); // Hook to dispatch actions
  const [file, setFile] = useState<File | null>(null); // State to manage the selected file
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  ); // Selecting the currently selected patient from the Redux store

  // Handler for file input change event
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]); // Set the selected file
    }
  };

  // Function to upload the file using the presigned URL
  const doUpload = async (fileObject: any) => {
    if (fileObject.data) {
      const url = fileObject.data.presignedUrl; // Get the presigned URL

      try {
        const response = await axios({
          method: "PUT",
          url: url,
          data: file,
          transformRequest: (d) => d,
        });
        console.log("Do upload response:", response);
        if (response.config.url) {
          dispatch(setNewFileUrl(response.config.url)); // Dispatch action to set new file URL
        }
        setFile(null); // Reset the file state
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("No presigned URL found");
    }
  };

  // Mutation hook for uploading file metadata
  const [mutate, { isLoading }] = useFilesPutFilesPutMutation();

  // Handler for the upload button click event
  const handleUpload = async () => {
    if (file) {
      const fileInfo: FilesPutFilesPutApiArg = {
        file: {
          patientId: selectedPatient,
          filename: file.name,
          filetype: file.type,
          description: "test",
        },
      };
      try {
        const response = await mutate(fileInfo); // Upload file metadata
        console.log("Handle upload response:", response);
        console.log("isLoading: ", isLoading);

        await doUpload(response); // Upload the file using the presigned URL
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <>
      {/* Stack container for the file uploader */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        {/* Title */}
        <Typography variant="h4" sx={{ mb: 2 }}>
          Documents
        </Typography>
        {/* Stack container for the buttons */}
        <Stack direction="row" spacing={2}>
          {/* Button to attach a document */}
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<AttachFile />}
            sx={{ mr: 2 }}
          >
            {file && file.name}
            {!file && "Attach document"}
            <VisuallyHiddenInput onChange={handleFileChange} type="file" />
          </Button>
          {/* Button to upload the selected file */}
          {file && (
            <Button
              variant="contained"
              startIcon={<CloudUpload />}
              onClick={handleUpload}
            >
              Upload file
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default SingleFileUploader;
