import React from "react";
import styled from "@emotion/styled";

import { Avatar, Grid, Typography } from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const { user } = useAuth0();

  return (
    <Footer {...rest}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          {user && <Avatar alt={user.name} />}
          {/* Demo data */}
          {!user && (
            <Avatar alt="Guest user" src="/static/img/avatars/guest-user.jpg" />
          )}
        </Grid>
        <Grid item>
          {user && (
            <FooterText sx={{ textTransform: "capitalize" }} variant="body2">
              {user.name}
            </FooterText>
          )}
          {/* Demo data */}
          {!user && <FooterText variant="body2">Guest User</FooterText>}
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
