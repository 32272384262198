// React imports
import React from "react";
import { useParams } from "react-router-dom";

// Redux imports
import { useDispatch } from "react-redux";
import { setSelectedPatient } from "../../../redux/slices/selectedPatientSlice";

// MUI imports
import { Box, Divider } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import FileList from "./FileList";
import SingleFileUploader from "./TestFileUpload";
import PatientDetailsPrompt from "../../loaders/PatientDetailsPrompt";
import PatientDetails from "./PatientDetails";

// Styled component for UploaderContainer
const UploaderContainer = styled(Box)`
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.024) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
  background-color: white;
  border-radius: 6px;
  height: calc(100vh - 187px);
  min-height: 662px;
  overflow: auto;
`;

// PatientFileUploader component definition
const PatientFileUploader: React.FC = () => {
  // Get the selected patient from the URL params
  const selectedPatient = useParams<{ id: string }>().id;
  const dispatch = useDispatch();

  // If a patient is selected, set the selected patient in the Redux store
  if (selectedPatient) {
    dispatch(setSelectedPatient(Number(selectedPatient)));
  }

  // Variable to hold the content to be rendered
  let content = null;

  // If no patient is selected, show the PatientDetailsPrompt component
  if (!selectedPatient) {
    content = <PatientDetailsPrompt />;
  }
  // If a patient is selected, show the PatientDetails, SingleFileUploader, and FileList components
  else {
    content = (
      <React.Fragment>
        <PatientDetails />
        <Box sx={{ pt: 0, px: 6, pb: 6 }}>
          <Divider sx={{ mt: 8, mb: 2 }} />
          <SingleFileUploader />
          <FileList />
        </Box>
      </React.Fragment>
    );
  }

  // Return the content wrapped in the UploaderContainer component
  return <UploaderContainer>{content}</UploaderContainer>;
};

// Exporting the PatientFileUploader component as default
export default PatientFileUploader;
