import { createSlice } from "@reduxjs/toolkit";
import eventDataSekari from "../../mockData/eventDataSekari";

export interface MockTimelineState {
  mockTimeline: any[];
}

const initialState: MockTimelineState = {
  mockTimeline: eventDataSekari,
};

const mockTimelineSlice = createSlice({
  name: "mockTimeline",
  initialState,
  reducers: {
    changeCommentTimeline: (state, action) => {
      state.mockTimeline = action.payload;
    },
  },
});

export const { changeCommentTimeline } = mockTimelineSlice.actions;
export default mockTimelineSlice.reducer;
