import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectionState {
  selectedItem: string | null;
}

const initialState: SelectionState = {
  selectedItem: null,
};

const selectionSlice = createSlice({
  name: "fileSelection",
  initialState,
  reducers: {
    setSelectedFileItem(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    clearSelectedFileItem(state) {
      state.selectedItem = null;
    },
  },
});

export const { setSelectedFileItem, clearSelectedFileItem } =
  selectionSlice.actions;
export default selectionSlice.reducer;
