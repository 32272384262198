// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  THINLAYER: "THINLAYER",
  RAMSAY: "RAMSAY",
  NORTHWELL: "NORTHWELL",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  PHOENIX: "PHOENIX",
  SPIRE: "SPIRE",
  SIDELIGHT: "SIDELIGHT",
};
