// React imports
import React from "react";

// Redux imports
import { useDispatch } from "react-redux";
import { changeSelectedEvent } from "../../redux/slices/selectedEventSlice";

// MUI imports
import { Box, Link } from "@mui/material";
import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridEventListener,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import styled from "@emotion/styled";

// API imports
import { PatientTimeline } from "../../api/jabsCentralApiPrivate";

// Local imports
import TypeLabel from "./eventComponents/TypeLabel";

// Define the columns for the DataGrid
const columns: GridColDef[] = [
  {
    field: "eventDateTime",
    headerName: "Start date",
    width: 120,
    type: "date",
    valueFormatter: (params) => {
      return params ? new Date(params).toLocaleDateString() : "N/A";
    },
  },

  {
    field: "eventType",
    headerName: "Type",
    renderCell: (params) => {
      return <TypeLabel type={params.value} />;
    },
    width: 150,
  },
  {
    field: "eventDescription",
    headerName: "Description",
    flex: 2,
    minWidth: 200,
  },
  {
    field: "eventTitle",
    headerName: "Source",
    renderCell: (params) => {
      return (
        <Link
          sx={{ fontWeight: 800 }}
          href={params.row.fileUrl}
          target="_blank"
        >
          {params.value}
        </Link>
      );
    },
    flex: 1,
    minWidth: 150,
  },
  {
    field: "author",
    headerName: "Author",

    width: 150,
  },
];

// Styled component for the timeline container
const TimelineContainer = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 260px)",
  overflowY: "auto",
}));

// Interface defining the props for TableTimeline component
interface TableTimelineProps {
  timeline: PatientTimeline[];
  handleOpen: () => void;
}

const TableTimeline = ({ timeline, handleOpen }: TableTimelineProps) => {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Event listener for row click
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    dispatch(changeSelectedEvent(params.row as PatientTimeline));
    handleOpen();
  };

  return (
    <TimelineContainer>
      <DataGridPro
        columns={columns}
        rows={timeline}
        getRowId={(row) => row.eventId}
        getRowHeight={() => "auto"}
        disableDensitySelector // Disable the density selector
        disableColumnSelector // Disable the column selector
        checkboxSelection // Enable checkbox selection
        disableRowSelectionOnClick // Disable row selection on click
        onRowClick={handleRowClick}
        slots={{ toolbar: GridToolbar }} // Set the toolbar slot to GridToolba
        slotProps={{
          toolbar: {
            showQuickFilter: true, // Show the quick filter in the toolbar
          },
        }}
        sx={{
          pt: 1,
          [`& .${gridClasses.cell}`]: {
            py: 3,
          },
        }}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          }, // Set the pinned columns
          // sorting: { sortModel: [{ field: "event_date", sort: "asc" }] },
        }}
      />
    </TimelineContainer>
  );
};

export default TableTimeline;
