// React imports
import React from "react";
import { useParams } from "react-router-dom";

// Redux imports
import { useDispatch } from "react-redux";
import { setSelectedPatient } from "../../redux/slices/selectedPatientSlice";

// Local imports
import SelectedPatientView from "../../components/patientView/SelectedPatientView";
import NoSelectedPatient from "../../components/patientView/NoSelectedPatient";

function PatientViewPage() {
  // Get the selected patient from the URL params
  const urlPatient = useParams<{ id: string }>().id;
  // Convert the selected patient to a number and set to -1 if not present
  const selectedPatient = urlPatient ? urlPatient : "-1";
  const dispatch = useDispatch();

  // Set the selected patient in the Redux store
  dispatch(setSelectedPatient(Number(selectedPatient)));

  let content = null;

  // If the selected patient is -1, show the NoSelectedPatient component
  if (selectedPatient === "-1") {
    content = <NoSelectedPatient />;
  } else {
    // If a patient is selected, show the SelectedPatientView component
    content = <SelectedPatientView />;
  }

  return content;
}

export default PatientViewPage;
