import { createSlice } from "@reduxjs/toolkit";
import { PatientTimeline } from "../../api/jabsCentralApiPrivate";

export interface SelectedEventState {
  selectedEvent: PatientTimeline;
}

const initialState: SelectedEventState = {
  selectedEvent: {
    patientId: null,
    eventId: null,
    eventDateTime: null,
    eventType: null,
    eventTitle: "Title",
    eventDescription: null,
    fileId: null,
    fileDateTime: null,
    fileUrl: null,
    author: null,
    status: null,
  },
};

const selectedEventSlice = createSlice({
  name: "selectedEvent",
  initialState,
  reducers: {
    changeSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
});

export const { changeSelectedEvent } = selectedEventSlice.actions;
export default selectedEventSlice.reducer;
