// React imports
import React from "react";

// MUI imports
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

// Component imports
import TLIcon from "../../icons/TLIcon";

interface TypeLabelProps {
  type: string;
}

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  color: theme.palette.grey[500],
}));

const TypeLabel: React.FC<TypeLabelProps> = ({ type }) => {
  const capitalizeType = type.charAt(0).toUpperCase() + type.slice(1);
  let iconName = "";
  switch (capitalizeType) {
    case "Admission":
      iconName = "AdmissionIcon";
      break;
    case "Appointment":
      iconName = "event";
      break;
    case "Consultation":
      iconName = "StethoscopeIcon";
      break;
    case "Diagnosis":
      iconName = "DiagnosisIcon";
      break;
    case "Discharge":
      iconName = "DischargeIcon";
      break;
    case "Measurement":
      iconName = "MeasurementIcon";
      break;
    case "Medication":
      iconName = "TreatmentIcon";
      break;
    case "Meeting":
      iconName = "groups";
      break;
    case "Note":
      iconName = "note";
      break;
    case "Observation":
      iconName = "preview";
      break;
    case "Procedure":
      iconName = "ProcedureIcon";
      break;
    case "Treatment":
      iconName = "TreatmentIcon";
      break;
    default:
      iconName = "event";
      break;
  }
  return (
    <Container>
      <TLIcon name={iconName} />
      <Typography variant="overline" sx={{ fontWeight: 800, lineHeight: 1 }}>
        {capitalizeType}
      </Typography>
    </Container>
  );
};

export default TypeLabel;
