import React from "react";

// Importing necessary components and libraries from MUI and other sources
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { AddCircle } from "@mui/icons-material";

// Importing API and other components
import { PatientMedications } from "../../../api/jabsCentralApiPrivate";
import MedicationsList from "./MedicationsList";
import { usePatientPatientIdUpdatesGetPatientPatientIdUpdatesGetQuery } from "../../../api/jabsCentralApiPrivate";

// Styled components for UploaderContainer and ExistingList
const UploaderContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
  borderRadius: "6px",
  padding: " 16px",
}));
const ExistingList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  margin: "0",
}));

// Interface for MedicationsProps
interface MedicationsProps {
  medications: PatientMedications[];
  id: string;
}

// Main Medications component
const Medications = ({ medications, id }: MedicationsProps) => {
  // Fetching patient updates using a custom hook
  const {
    data: updates,
    error: updatesError,
    isLoading: isUpdatesLoading,
    isFetching: isUpdatesFetching,
  } = usePatientPatientIdUpdatesGetPatientPatientIdUpdatesGetQuery({
    patientId: id,
  });

  // Variable to hold the content to be rendered
  let content = null;

  // If data is loading or fetching, show a loading spinner and existing medications
  if (isUpdatesLoading || isUpdatesFetching) {
    content = (
      <Box>
        <Stack direction={"row"} alignItems="center">
          <IconButton disabled>
            <AddCircle />
          </IconButton>
          <Typography variant="h4">Medications</Typography>
        </Stack>
        <UploaderContainer>
          <ExistingList dense>
            {medications?.map((item, index) => (
              <ListItemButton key={index}>
                <ListItemText primary={item.description} />
              </ListItemButton>
            ))}
          </ExistingList>
          <Stack direction="row" sx={{ justifyContent: "center", mt: 6 }}>
            <CircularProgress color="secondary" />
          </Stack>
        </UploaderContainer>
      </Box>
    );
  }
  // If there is an error, display the error message
  else if (updatesError) {
    console.log(updatesError);
    content = (
      <div>
        Error:{" "}
        {updatesError
          ? String(updatesError) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  }
  // If data is successfully fetched, display the medications list
  else {
    let proposedItems = [] as any[];
    if (updates && updates.medications) {
      proposedItems = updates.medications.map(({ medication, updateId }) => ({
        item: medication,
        id: updateId,
      }));
    }

    content = (
      <MedicationsList
        existingItems={medications || []}
        proposedItems={proposedItems}
      />
    );
  }

  // Return the content wrapped in a Box component
  return <Box>{content}</Box>;
};

// Exporting the Medications component as default
export default Medications;
