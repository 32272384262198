const caseData = [
  {
    plaintiff_information: {
      full_name: "John Doe",
      date_of_birth: "1980-05-12",
      contact_information: {
        address: "123 Main St, Springfield, IL",
        phone_number: "555-123-4567",
        email: "john.doe@example.com",
      },
      social_security_number: "123-45-6789",
      health_insurance_details: "Blue Cross Blue Shield, Member ID: BCBS123456",
    },
    defendant_information: {
      full_name: "Dr. Sarah Miller",
      title_and_role: "Surgeon",
      contact_information: {
        address: "456 Hospital Way, Springfield, IL",
        phone_number: "555-987-6543",
      },
      license_number: "IL-123456",
      insurance_provider: "ProAssurance Malpractice Insurance",
    },
    incident_information: {
      treatment_date: "2023-03-15",
      location_of_treatment: "Springfield General Hospital",
      treatment_description: "Appendectomy procedure",
      allegations_of_malpractice:
        "Left a surgical tool inside patient's abdomen",
      description_of_malpractice:
        "During surgery, a surgical sponge was left inside the patient's body, leading to infection.",
    },
    injury_details: {
      nature_of_injury: "Severe abdominal infection",
      date_injury_discovered: "2023-03-22",
      impact_of_injury:
        "Hospitalization for 3 weeks, ongoing pain and recovery",
      current_medical_status: "Recovering but with long-term pain management",
      medical_records: "Attached",
    },
    damages_and_losses: {
      medical_expenses: 50000,
      loss_of_wages: 10000,
      pain_and_suffering: "Emotional distress, physical pain",
      impact_on_quality_of_life:
        "Limited mobility, unable to work for 3 months",
      permanent_disability: "None",
    },
    witness_information: [
      {
        name: "Jane Doe",
        contact_information: {
          phone_number: "555-555-1234",
        },
        relationship_to_plaintiff: "Spouse",
      },
    ],
    medical_history: {
      relevant_medical_history:
        "No significant medical history prior to the incident",
      other_healthcare_providers: "None",
      medications: "None",
    },
    legal_representation: {
      plaintiff_attorney: {
        name: "Attorney Lisa Smith",
        contact_information: {
          phone_number: "555-321-7654",
          email: "lisa.smith@lawfirm.com",
        },
      },
      defendant_attorney: "Unknown",
    },
    statute_of_limitations_information: {
      date_injury_discovered: "2023-03-22",
    },
    authorization_and_consent: {
      consent_to_access_medical_records: true,
      signature: "John Doe",
      date: "2023-04-01",
    },
    supporting_documents: ["medical_records.pdf", "hospital_bills.pdf"],
  },
  {
    plaintiff_information: {
      full_name: "Emily Johnson",
      date_of_birth: "1992-08-19",
      contact_information: {
        address: "789 Maple Ave, Chicago, IL",
        phone_number: "555-222-3333",
        email: "emily.johnson@example.com",
      },
      social_security_number: "456-78-9012",
      health_insurance_details: "UnitedHealthcare, Member ID: UHC987654",
    },
    defendant_information: {
      full_name: "Dr. Michael Brown",
      title_and_role: "OB-GYN",
      contact_information: {
        address: "890 Women’s Clinic, Chicago, IL",
        phone_number: "555-444-7777",
      },
      license_number: "IL-654321",
      insurance_provider: "Doctors Company Malpractice Insurance",
    },
    incident_information: {
      treatment_date: "2022-11-10",
      location_of_treatment: "Chicago Women's Clinic",
      treatment_description: "Prenatal care and delivery",
      allegations_of_malpractice: "Failure to diagnose gestational diabetes",
      description_of_malpractice:
        "The doctor failed to diagnose gestational diabetes, leading to complications during delivery.",
    },
    injury_details: {
      nature_of_injury: "Complications during childbirth",
      date_injury_discovered: "2022-11-12",
      impact_of_injury: "Emergency C-section, prolonged recovery",
      current_medical_status: "Stable but with continued monitoring",
      medical_records: "Attached",
    },
    damages_and_losses: {
      medical_expenses: 75000,
      loss_of_wages: 0,
      pain_and_suffering: "Emotional trauma, physical pain",
      impact_on_quality_of_life: "Increased anxiety, prolonged recovery time",
      permanent_disability: "None",
    },
    witness_information: [
      {
        name: "Mark Johnson",
        contact_information: {
          phone_number: "555-222-6666",
        },
        relationship_to_plaintiff: "Husband",
      },
    ],
    medical_history: {
      relevant_medical_history: "No significant history before pregnancy",
      other_healthcare_providers: "None",
      medications: "Prenatal vitamins",
    },
    legal_representation: {
      plaintiff_attorney: {
        name: "Attorney Robert Garcia",
        contact_information: {
          phone_number: "555-888-9999",
          email: "robert.garcia@lawfirm.com",
        },
      },
      defendant_attorney: "Unknown",
    },
    statute_of_limitations_information: {
      date_injury_discovered: "2022-11-12",
    },
    authorization_and_consent: {
      consent_to_access_medical_records: true,
      signature: "Emily Johnson",
      date: "2022-12-01",
    },
    supporting_documents: ["medical_records.pdf", "hospital_bills.pdf"],
  },
  {
    plaintiff_information: {
      full_name: "Mark Thompson",
      date_of_birth: "1975-02-22",
      contact_information: {
        address: "111 Oak St, Denver, CO",
        phone_number: "555-666-7777",
        email: "mark.thompson@example.com",
      },
      social_security_number: "789-12-3456",
      health_insurance_details: "Aetna, Member ID: AET234567",
    },
    defendant_information: {
      full_name: "Dr. Anna White",
      title_and_role: "Orthopedic Surgeon",
      contact_information: {
        address: "555 Bone Care Center, Denver, CO",
        phone_number: "555-123-9999",
      },
      license_number: "CO-234567",
      insurance_provider: "Medical Protective",
    },
    incident_information: {
      treatment_date: "2021-05-18",
      location_of_treatment: "Bone Care Center",
      treatment_description: "Knee replacement surgery",
      allegations_of_malpractice: "Improper surgical technique",
      description_of_malpractice:
        "Incorrectly placed knee implant led to chronic pain and mobility issues.",
    },
    injury_details: {
      nature_of_injury: "Chronic knee pain and reduced mobility",
      date_injury_discovered: "2021-06-05",
      impact_of_injury: "Inability to walk without assistance, long-term pain",
      current_medical_status: "Requires revision surgery",
      medical_records: "Attached",
    },
    damages_and_losses: {
      medical_expenses: 120000,
      loss_of_wages: 50000,
      pain_and_suffering: "Severe pain, emotional distress",
      impact_on_quality_of_life: "Permanent disability, unable to work",
      permanent_disability: "Partial",
    },
    witness_information: [
      {
        name: "Laura Thompson",
        contact_information: {
          phone_number: "555-777-8888",
        },
        relationship_to_plaintiff: "Wife",
      },
    ],
    medical_history: {
      relevant_medical_history: "History of knee arthritis",
      other_healthcare_providers: "None",
      medications: "Pain management medications",
    },
    legal_representation: {
      plaintiff_attorney: {
        name: "Attorney Susan Roberts",
        contact_information: {
          phone_number: "555-999-0000",
          email: "susan.roberts@lawfirm.com",
        },
      },
      defendant_attorney: "Unknown",
    },
    statute_of_limitations_information: {
      date_injury_discovered: "2021-06-05",
    },
    authorization_and_consent: {
      consent_to_access_medical_records: true,
      signature: "Mark Thompson",
      date: "2021-06-20",
    },
    supporting_documents: ["medical_records.pdf", "knee_surgery_bills.pdf"],
  },
  {
    plaintiff_information: {
      full_name: "Sophia Williams",
      date_of_birth: "1985-09-30",
      contact_information: {
        address: "456 Elm St, San Francisco, CA",
        phone_number: "555-234-5678",
        email: "sophia.williams@example.com",
      },
      social_security_number: "987-65-4321",
      health_insurance_details: "Kaiser Permanente, Member ID: KP567890",
    },
    defendant_information: {
      full_name: "Dr. William Roberts",
      title_and_role: "Radiologist",
      contact_information: {
        address: "789 Radiology Center, San Francisco, CA",
        phone_number: "555-987-6543",
      },
      license_number: "CA-765432",
      insurance_provider: "Norcal Mutual",
    },
    incident_information: {
      treatment_date: "2023-01-10",
      location_of_treatment: "San Francisco Radiology Center",
      treatment_description: "Breast cancer screening (mammogram)",
      allegations_of_malpractice: "Failure to identify a malignant tumor",
      description_of_malpractice:
        "The radiologist failed to detect a tumor during the mammogram, leading to delayed diagnosis of breast cancer.",
    },
    injury_details: {
      nature_of_injury: "Delayed cancer diagnosis",
      date_injury_discovered: "2023-06-05",
      impact_of_injury: "Stage III cancer, requiring aggressive treatment",
      current_medical_status: "Undergoing chemotherapy",
      medical_records: "Attached",
    },
    damages_and_losses: {
      medical_expenses: 200000,
      loss_of_wages: 30000,
      pain_and_suffering: "Physical pain from treatment, emotional distress",
      impact_on_quality_of_life:
        "Significant reduction in life quality due to late-stage cancer diagnosis",
      permanent_disability: "Possible, depending on treatment outcomes",
    },
    witness_information: [
      {
        name: "Rachel Lee",
        contact_information: {
          phone_number: "555-321-4567",
        },
        relationship_to_plaintiff: "Friend",
      },
    ],
    medical_history: {
      relevant_medical_history: "No prior history of cancer",
      other_healthcare_providers: "None",
      medications: "Chemotherapy drugs",
    },
    legal_representation: {
      plaintiff_attorney: {
        name: "Attorney James Clark",
        contact_information: {
          phone_number: "555-111-2222",
          email: "james.clark@lawfirm.com",
        },
      },
      defendant_attorney: "Unknown",
    },
    statute_of_limitations_information: {
      date_injury_discovered: "2023-06-05",
    },
    authorization_and_consent: {
      consent_to_access_medical_records: true,
      signature: "Sophia Williams",
      date: "2023-07-01",
    },
    supporting_documents: ["medical_records.pdf", "treatment_costs.pdf"],
  },
  {
    plaintiff_information: {
      full_name: "Michael Green",
      date_of_birth: "1990-11-17",
      contact_information: {
        address: "789 Pine St, Miami, FL",
        phone_number: "555-888-1234",
        email: "michael.green@example.com",
      },
      social_security_number: "654-32-1098",
      health_insurance_details: "Cigna, Member ID: CIG234567",
    },
    defendant_information: {
      full_name: "Dr. Rachel Edwards",
      title_and_role: "Cardiologist",
      contact_information: {
        address: "123 Heart Care Clinic, Miami, FL",
        phone_number: "555-777-5678",
      },
      license_number: "FL-345678",
      insurance_provider: "Coverys",
    },
    incident_information: {
      treatment_date: "2022-04-10",
      location_of_treatment: "Miami Heart Care Clinic",
      treatment_description: "Heart catheterization",
      allegations_of_malpractice:
        "Improper catheter insertion causing heart damage",
      description_of_malpractice:
        "The catheter was improperly inserted during a routine heart procedure, causing significant damage to the heart tissue.",
    },
    injury_details: {
      nature_of_injury: "Heart tissue damage",
      date_injury_discovered: "2022-04-20",
      impact_of_injury:
        "Increased risk of heart failure, ongoing cardiac monitoring",
      current_medical_status: "Requires long-term cardiac care",
      medical_records: "Attached",
    },
    damages_and_losses: {
      medical_expenses: 100000,
      loss_of_wages: 20000,
      pain_and_suffering: "Chronic pain, emotional trauma",
      impact_on_quality_of_life:
        "Reduced ability to engage in physical activity, emotional distress",
      permanent_disability: "Partial heart function loss",
    },
    witness_information: [
      {
        name: "David Green",
        contact_information: {
          phone_number: "555-234-9999",
        },
        relationship_to_plaintiff: "Brother",
      },
    ],
    medical_history: {
      relevant_medical_history: "History of heart disease",
      other_healthcare_providers: "None",
      medications: "Blood thinners",
    },
    legal_representation: {
      plaintiff_attorney: {
        name: "Attorney Carol Young",
        contact_information: {
          phone_number: "555-555-4567",
          email: "carol.young@lawfirm.com",
        },
      },
      defendant_attorney: "Unknown",
    },
    statute_of_limitations_information: {
      date_injury_discovered: "2022-04-20",
    },
    authorization_and_consent: {
      consent_to_access_medical_records: true,
      signature: "Michael Green",
      date: "2022-05-01",
    },
    supporting_documents: ["medical_records.pdf", "surgery_bills.pdf"],
  },
];

export default caseData;
