import ReactQuill from "react-quill";
import { useState } from "react";
import styled from "@emotion/styled";
import Markdown from "react-markdown";
import { renderToString } from "react-dom/server";
import { remark } from "remark";
import rehypeDomParse from "rehype-dom-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
import { Button, Stack } from "@mui/material";

import "react-quill/dist/quill.snow.css";
import "./aiTextEdit.css";
// import "./customQuill.css";

interface TestProps {
  content: {
    id: string;
    summaryText: string;
    summaryDate: string;
    day: number;
  };
}

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ list: "ordered" }, { list: "bullet" }],

  [{ header: [1, 2, 3, 4, 5, 6, false] }],
];

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 50px;
    position: relative;
  }
`;

const Test = ({ content }: TestProps) => {
  const renderedString = renderToString(
    <Markdown>{content.summaryText}</Markdown>
  );

  const [value, setValue] = useState(renderedString);
  const [markdown, setMarkdown] = useState(content.summaryText);

  function htmlToMarkdown(htmlText: string) {
    const file = remark()
      .use(rehypeDomParse)
      .use(rehypeRemark)
      .use(remarkStringify)
      .processSync(htmlText);

    return String(file);
  }

  function handleChange(value: string) {
    setValue(value);
    setMarkdown(htmlToMarkdown(value));
  }

  return (
    <div>
      <QuillWrapper>
        <ReactQuill
          modules={{
            toolbar: toolbarOptions,
          }}
          theme="snow"
          value={value}
          onChange={handleChange}
          placeholder="Type something.."
        />
      </QuillWrapper>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2 }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => console.log(markdown)}
        >
          Approve
        </Button>
      </Stack>
    </div>
  );
};

export default Test;
