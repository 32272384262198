import React from "react";
import styled from "@emotion/styled";
// import poweredBy from "../theme/ThinLayer-powered-by.svg";
import SidelightLogoPowered from "../vendor/SidelightLogoPowered";

import {
  Grid,
  List,
  // ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton,
} from "@mui/material";

interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: React.ElementType<any> | undefined;
  href?: string;
}

const Wrapper = styled.div`
  padding: 0 ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ThisList = styled(List)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;
const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

// const ListItemText = styled(MuiListItemText)`
//   span {
//     color: ${(props) => props.theme.footer.color};
//   }
// `;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid container item xs={12} justifyContent="flex-end">
          <ThisList>
            <ListItemButton>
              {/* <ListItemText
                primary={`© ${new Date().getFullYear()} - JABS Medtech`}
              /> */}
              <SidelightLogoPowered />
            </ListItemButton>
          </ThisList>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
