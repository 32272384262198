import React from "react";
import ListApprover from "./listApprover";
import { useState } from "react";

// Interface defining the props for ConditionsList component
interface ConditionsListProps {
  existingItems: any[];
  proposedItems: any[];
}

// ConditionsList component definition
const ConditionsList = ({
  existingItems,
  proposedItems,
}: ConditionsListProps) => {
  // State to manage existing conditions
  const [existingConditions, setExistingConditions] = useState(existingItems);
  // State to manage proposed conditions
  const [proposedConditions, setProposedConditions] = useState(proposedItems);

  // Handler to update existing conditions
  const existingHandler = (items: any[]) => {
    setExistingConditions(items);
  };

  // Handler to update proposed conditions
  const proposedHandler = (items: any[]) => {
    setProposedConditions(items);
  };

  return (
    <div>
      {/* ListApprover component to manage and display conditions */}
      <ListApprover
        existingItems={existingConditions}
        existingHandler={existingHandler}
        proposedItems={proposedConditions}
        proposedHandler={proposedHandler}
        title="Conditions"
      />
    </div>
  );
};

// Exporting the ConditionsList component as default
export default ConditionsList;
