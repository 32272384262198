import { SvgIcon } from "@mui/material";

const AdmissionIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M160-80q-33 0-56.5-23.5T80-160v-640q0-33 23.5-56.5T160-880h360q33 0 56.5 23.5T600-800v640q0 33-23.5 56.5T520-80H160Zm0-459q18-11 38-16t42-5h200q22 0 42 5t38 16v-261H160v261Zm180-61q-33 0-56.5-23.5T260-680q0-33 23.5-56.5T340-760q33 0 56.5 23.5T420-680q0 33-23.5 56.5T340-600Zm440 260L640-480l140-140 56 56-43 44h127v80H793l43 44-56 56ZM300-200h80v-80h80v-80h-80v-80h-80v80h-80v80h80v80Z" />
      </svg>
    </SvgIcon>
  );
};

const AiIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M294.468 268.534c7.972 0 14.705 5.575 14.705 12.864v131.756c0 30.291-24.575 54.846-54.89 54.846h-15.98c-30.314 0-54.89-24.555-54.89-54.846V377.13c0-7.29 6.733-12.864 14.706-12.864 7.972 0 14.705 5.575 14.705 12.864v40.172c0 11.83 9.6 21.423 21.445 21.423h24.049c11.844 0 21.444-9.592 21.444-21.423V281.398c0-7.29 6.734-12.864 14.706-12.864Zm-151.357-58.82c7.305 0 12.889 6.756 12.889 14.754 0 7.997-5.584 14.753-12.889 14.753h-40.484c-11.753 0-21.294 9.624-21.294 21.512v24.248c0 11.889 9.541 21.513 21.294 21.513H239.11c7.305 0 12.889 6.755 12.889 14.753 0 7.997-5.584 14.753-12.889 14.753l-132.685-.002-.741-.005C75.91 335.506 52 311.018 52 280.975v-16.236c0-30.381 24.432-55.025 54.587-55.025h36.524Zm113.778 0 132.712.003.714.005c29.775.487 53.685 24.975 53.685 55.017v16.236C444 311.357 419.568 336 389.413 336H352.89c-7.305 0-12.889-6.756-12.889-14.753 0-7.998 5.584-14.753 12.889-14.753h40.484c11.753 0 21.294-9.624 21.294-21.513v-24.248c0-11.888-9.541-21.512-21.294-21.512H256.89c-7.305 0-12.889-6.756-12.889-14.753 0-7.998 5.584-14.754 12.889-14.754ZM254.284 76c30.314 0 54.89 24.555 54.89 54.846v36.023c0 7.29-6.734 12.864-14.706 12.864-7.972 0-14.706-5.575-14.706-12.864v-40.172c0-11.83-9.6-21.423-21.444-21.423h-24.049c-11.844 0-21.445 9.592-21.445 21.423v135.905c0 7.29-6.733 12.864-14.705 12.864-7.973 0-14.706-5.575-14.706-12.864V130.846c0-30.291 24.576-54.846 54.89-54.846h15.98ZM400 44l20.053 43.947L464 108l-43.947 20.053L400 172l-20.053-43.947L336 108l43.947-20.053L400 44Z"
        />
      </svg>
    </SvgIcon>
  );
};

const DiagnosisIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M320-200h320v-80H320v80Zm0-120h320v-80H320v80Zm160-148q66-60 113-106.5t47-97.5q0-36-26-62t-62-26q-21 0-40.5 8.5T480-728q-12-15-31.5-23.5T408-760q-36 0-62 26t-26 62q0 51 45.5 96T480-468ZM720-80H240q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h480q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80Z" />
      </svg>
    </SvgIcon>
  );
};

const DischargeIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M160-80q-33 0-56.5-23.5T80-160v-640q0-33 23.5-56.5T160-880h360q33 0 56.5 23.5T600-800v640q0 33-23.5 56.5T520-80H160Zm0-459q18-11 38-16t42-5h200q22 0 42 5t38 16v-261H160v261Zm180-61q-33 0-56.5-23.5T260-680q0-33 23.5-56.5T340-760q33 0 56.5 23.5T420-680q0 33-23.5 56.5T340-600Zm440 260-56-56 43-44H640v-80h127l-43-44 56-56 140 140-140 140ZM300-200h80v-80h80v-80h-80v-80h-80v80h-80v80h80v80Z" />
      </svg>
    </SvgIcon>
  );
};

const LungIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 640 512"
      >
        <path
          fill="currentColor"
          d="M320 0c17.7 0 32 14.3 32 32V164.1c0 16.4 8.4 31.7 22.2 40.5l9.8 6.2V165.3C384 127 415 96 453.3 96c21.7 0 42.8 10.2 55.8 28.8c15.4 22.1 44.3 65.4 71 116.9c26.5 50.9 52.4 112.5 59.6 170.3c.2 1.3 .2 2.6 .2 4v7c0 49.1-39.8 89-89 89c-7.3 0-14.5-.9-21.6-2.7l-72.7-18.2C414 480.5 384 442.1 384 398V325l90.5 57.6c7.5 4.7 17.3 2.5 22.1-4.9s2.5-17.3-4.9-22.1L384 287.1v-.4l-44.1-28.1c-7.3-4.6-13.9-10.1-19.9-16.1c-5.9 6-12.6 11.5-19.9 16.1L256 286.7 161.2 347l-13.5 8.6c0 0 0 0-.1 0c-7.4 4.8-9.6 14.6-4.8 22.1c4.7 7.5 14.6 9.7 22.1 4.9l91.1-58V398c0 44.1-30 82.5-72.7 93.1l-72.7 18.2c-7.1 1.8-14.3 2.7-21.6 2.7c-49.1 0-89-39.8-89-89v-7c0-1.3 .1-2.7 .2-4c7.2-57.9 33.1-119.4 59.6-170.3c26.8-51.5 55.6-94.8 71-116.9c13-18.6 34-28.8 55.8-28.8C225 96 256 127 256 165.3v45.5l9.8-6.2c13.8-8.8 22.2-24.1 22.2-40.5V32c0-17.7 14.3-32 32-32z"
        />
      </svg>
    </SvgIcon>
  );
};
const MeasurementIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M160-160q-33 0-56.5-23.5T80-240v-200h215l69 138q5 11 15 16.5t21 5.5q11 0 21-5.5t15-16.5l124-248 44 88q5 11 15 16.5t21 5.5h240v200q0 33-23.5 56.5T800-160H160ZM80-520v-200q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v200H665l-69-138q-5-11-15-15.5t-21-4.5q-11 0-21 4.5T524-658L400-410l-44-88q-5-11-15-16.5t-21-5.5H80Z" />
      </svg>
    </SvgIcon>
  );
};

const MicrobiologyIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96V384c0 53 43 96 96 96c28.6 0 54.2-12.5 71.8-32.3c.1-14.2 5.6-28.3 16.4-39.1c.2-.2 .1-.6-.2-.6c-30.9 0-56-25.1-56-56s25.1-56 56-56c.3 0 .4-.4 .2-.6c-21.9-21.9-21.9-57.3 0-79.2c2.4-2.4 5-4.6 7.8-6.5V96c17.7 0 32-14.3 32-32s-14.3-32-32-32H160 96 32zM96 192V96h64v96H96zM216 376c28.8 0 43.2 34.8 22.9 55.2c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0c20.4-20.4 55.2-5.9 55.2 22.9c0 13.3 10.7 24 24 24s24-10.7 24-24c0-28.8 34.8-43.2 55.2-22.9c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9C444.8 410.8 459.2 376 488 376c13.3 0 24-10.7 24-24s-10.7-24-24-24c-28.8 0-43.2-34.8-22.9-55.2c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0C410.8 259.2 376 244.8 376 216c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 28.8-34.8 43.2-55.2 22.9c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9c20.4 20.4 5.9 55.2-22.9 55.2c-13.3 0-24 10.7-24 24s10.7 24 24 24zm104-88a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm40 96a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"
        />
      </svg>
    </SvgIcon>
  );
};

const NurseIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M96 128V70.2c0-13.3 8.3-25.3 20.8-30l96-36c7.2-2.7 15.2-2.7 22.5 0l96 36c12.5 4.7 20.8 16.6 20.8 30V128h-.3c.2 2.6 .3 5.3 .3 8v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V136c0-2.7 .1-5.4 .3-8H96zm48 48c0 44.2 35.8 80 80 80s80-35.8 80-80V160H144v16zM111.9 327.7c10.5-3.4 21.8 .4 29.4 8.5l71 75.5c6.3 6.7 17 6.7 23.3 0l71-75.5c7.6-8.1 18.9-11.9 29.4-8.5C401 348.6 448 409.4 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-71.9 47-132.7 111.9-153.6zM208 48V64H192c-4.4 0-8 3.6-8 8V88c0 4.4 3.6 8 8 8h16v16c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h16c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8H240V48c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8z"
        />
      </svg>
    </SvgIcon>
  );
};
const OncologyIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M863-40 761-142q-18 11-38 16.5t-43 5.5q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-6 43.5T817-198L919-96l-56 56ZM680-200q33 0 56.5-23.5T760-280q0-33-23.5-56.5T680-360q-33 0-56.5 23.5T600-280q0 33 23.5 56.5T680-200ZM120-80v-240q0-50 35-85t85-35h80q50 0 85-35t35-85q0-17-11.5-28.5T400-600q-33 0-56.5-23.5T320-680v-200h240v80q117 0 198.5 81.5T840-520v61q-33-29-74-45t-86-16q-100 0-170 70t-70 170q0 21 3.5 41t10.5 39h-54q-17 0-28.5 11.5T360-160v80H120Z" />
      </svg>
    </SvgIcon>
  );
};
const OrthoIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
      >
        <path
          fill="currentColor"
          d="M480-72q-46 0-115.5-14T264-115q-11-5-17.5-15t-6.5-22v-40h-40q-17 0-28.5-11.5T160-232v-60q0-17 11.5-28.5T200-332h40v-80h-40q-17 0-28.5-11.5T160-452v-60q0-17 11.5-28.5T200-552h40v-80h-40q-17 0-28.5-11.5T160-672v-60q0-17 11.5-28.5T200-772h40v-60q0-22 18-33t38-3q1 0 55 18t129 18q75 0 129-18t55-18q20-8 38 3.5t18 32.5v60h40q17 0 28.5 11.5T800-732v60q0 17-11.5 28.5T760-632h-40v80h40q17 0 28.5 11.5T800-512v60q0 17-11.5 28.5T760-412h-40v80h40q17 0 28.5 11.5T800-292v60q0 17-11.5 28.5T760-192h-40v40q0 12-6.5 22T696-115q-31 15-100.5 29T480-72Zm0-534q38 0 80.5-7t79.5-20v-143q-38 11-79.5 17.5T480-752q-38 0-79.5-6.5T320-776v143q36 13 79 20t81 7Zm0 228q37 0 79.5-7t80.5-21v-144q-44 12-83.5 18t-76.5 6q-39 0-80-6t-80-18v144q38 14 80.5 21t79.5 7Zm0 226q38 0 80.5-7t79.5-20v-143q-38 11-79.5 17.5T480-298q-38 0-79.5-6.5T320-322v143q36 13 79 20t81 7Z"
        />
      </svg>
    </SvgIcon>
  );
};
const ProcedureIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M760-520q-38 0-81-21.5T599-601q-37-38-58.5-81T519-762q0-17 5-31t15-24q26-26 105.5-45.5T794-879q25 2 41.5 6t24.5 12q7 7 11.5 21.5T878-803q5 69-14 152t-46 110q-10 10-25.5 15.5T760-520Zm35-200q2-18 3-38t2-42q-20-1-40.5.5T719-796q11 8 21.5 17t19.5 18q10 10 18.5 20t16.5 21Zm-35 120q1-20-15-49.5T703-704q-25-25-53.5-40.5T601-760q2 23 17 52t37 51q24 24 52.5 39.5T760-600Zm92 388L664-400H264L52-612l56-56 188 188h400l212 212-56 56ZM320-80v-160q0-33 23.5-56.5T400-320h160q33 0 56.5 23.5T640-240v160H320Z" />
      </svg>
    </SvgIcon>
  );
};
const RamsayIcon = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="1em">
      <path
        fill="currentColor"
        d="M0 0h241.509v241.509H0zm270.491 0H512v241.509H270.491zM0 270.491h241.509V512H0zm512 0v222.201L290.492 270.491zM271.173 512.013V289.81L492.68 512.013z"
      />
    </svg>
  );
};
const StethoscopeIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M540-80q-108 0-184-76t-76-184v-23q-86-14-143-80.5T80-600v-240h120v-40h80v160h-80v-40h-40v160q0 66 47 113t113 47q66 0 113-47t47-113v-160h-40v40h-80v-160h80v40h120v240q0 90-57 156.5T360-363v23q0 75 52.5 127.5T540-160q75 0 127.5-52.5T720-340v-67q-35-12-57.5-43T640-520q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 70T800-407v67q0 108-76 184T540-80Z" />
      </svg>
    </SvgIcon>
  );
};

const TransferIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 640 512"
      >
        <path
          fill="currentColor"
          d="M0 48C0 21.5 21.5 0 48 0H368c26.5 0 48 21.5 48 48V96h50.7c17 0 33.3 6.7 45.3 18.7L589.3 192c12 12 18.7 28.3 18.7 45.3V256v32 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H576c0 53-43 96-96 96s-96-43-96-96H256c0 53-43 96-96 96s-96-43-96-96H48c-26.5 0-48-21.5-48-48V48zM416 256H544V237.3L466.7 160H416v96zM160 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm368-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM176 80v48l-48 0c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V192h48c8.8 0 16-7.2 16-16V144c0-8.8-7.2-16-16-16H240V80c0-8.8-7.2-16-16-16H192c-8.8 0-16 7.2-16 16z"
        />
      </svg>
    </SvgIcon>
  );
};
const TreatmentIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="m735-77 46-46-178-178-46 46q-37 37-37 89t37 89q37 37 89 37t89-37Zm102-102 46-46q37-37 37-89t-37-89q-37-37-89-37t-89 37l-46 46 178 178ZM280-600h400v-80H280v80Zm200-190q13 0 21.5-8.5T510-820q0-13-8.5-21.5T480-850q-13 0-21.5 8.5T450-820q0 13 8.5 21.5T480-790Zm-35 670H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h168q13-36 43.5-58t68.5-22q38 0 68.5 22t43.5 58h168q33 0 56.5 23.5T840-760v245q-42-10-83.5-1.5T680-485v-35H280v80h349l-80 80H280v80h195q-23 35-31.5 76.5T445-120Z" />
      </svg>
    </SvgIcon>
  );
};

const WardIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M120-880h80q17 0 28.5 11.5T240-840v760q-33 0-56.5-23.5T160-160v-640h-40q-17 0-28.5-11.5T80-840q0-17 11.5-28.5T120-880ZM360-80q-33 0-56.5-23.5T280-160v-640q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80H360Zm0-459q18-11 38-16t42-5h200q22 0 42 5t38 16v-261H360v261Zm180-61q-33 0-56.5-23.5T460-680q0-33 23.5-56.5T540-760q33 0 56.5 23.5T620-680q0 33-23.5 56.5T540-600Zm-40 400h80v-80h80v-80h-80v-80h-80v80h-80v80h80v80Z" />
      </svg>
    </SvgIcon>
  );
};

const XrayIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M0 64C0 46.3 14.3 32 32 32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V96C14.3 96 0 81.7 0 64zM256 96c-8.8 0-16 7.2-16 16v32H160c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v48H128c-8.8 0-16 7.2-16 16s7.2 16 16 16H240v70.6L189.1 307c-5.2-2-10.6-3-16.2-3h-2.1c-23.6 0-42.8 19.2-42.8 42.8c0 9.6 3.2 18.9 9.1 26.4l18.2 23.2c9.7 12.4 24.6 19.6 40.3 19.6H316.4c15.7 0 30.6-7.2 40.3-19.6l18.2-23.2c5.9-7.5 9.1-16.8 9.1-26.4c0-23.6-19.2-42.8-42.8-42.8H339c-5.5 0-11 1-16.2 3L272 326.6V256H384c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V176h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V112c0-8.8-7.2-16-16-16zM208 352a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm80 16a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"
        />
      </svg>
    </SvgIcon>
  );
};

type IconMap = {
  [key: string]: React.ComponentType;
};

const stringMap: IconMap = {
  AdmissionIcon: AdmissionIcon,
  AiIcon: AiIcon,
  DiagnosisIcon: DiagnosisIcon,
  DischargeIcon: DischargeIcon,
  LungIcon: LungIcon,
  MeasurementIcon: MeasurementIcon,
  MicrobiologyIcon: MicrobiologyIcon,
  NurseIcon: NurseIcon,
  OncologyIcon: OncologyIcon,
  OrthoIcon: OrthoIcon,
  ProcedureIcon: ProcedureIcon,
  RamsayIcon: RamsayIcon,
  StethoscopeIcon: StethoscopeIcon,
  TransferIcon: TransferIcon,
  TreatmentIcon: TreatmentIcon,
  WardIcon: WardIcon,
  XrayIcon: XrayIcon,
};

export {
  AdmissionIcon,
  AiIcon,
  DiagnosisIcon,
  DischargeIcon,
  LungIcon,
  MeasurementIcon,
  MicrobiologyIcon,
  NurseIcon,
  OncologyIcon,
  OrthoIcon,
  ProcedureIcon,
  RamsayIcon,
  StethoscopeIcon,
  TransferIcon,
  TreatmentIcon,
  WardIcon,
  XrayIcon,
  stringMap,
};
