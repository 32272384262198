// import { useGetSummaryQuery } from "../../redux/store";
// import Loader from "../Loader";
import Test from "./Test";

function SummaryTest() {
  // const { data, error, isLoading } = useGetSummaryQuery(4);
  const content = {
    id: "1",
    summaryText: `# h1 Heading
## h2 Heading
### h3 Heading
#### h4 Heading
`,
    summaryDate: "2021-09-01",
    day: 1,
  };

  // if (isLoading) {
  //   return <Loader />;
  // }

  // if (error) {
  //   return (
  //     <div>
  //       <div>
  //         <h2>Summary Query</h2>
  //         <p>Error: {JSON.stringify(error)}</p>
  //         <Test content={content} />
  //       </div>
  //     </div>
  //   );
  // }
  // console.log(data);
  // console.log(data.dailySummary[0].summaryText);
  // return <Test content={data.dailySummary[0]} />;
  return <Test content={content} />;
}

export default SummaryTest;
