// React imports
import React from "react";
import { Helmet } from "react-helmet-async";

//MUI imports
import { Box, Breadcrumbs, Card, Link, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import PatientDrawer from "../patientSelection/PatientDrawer";
import PatientTableLarge from "../patientSelection/PatientTableLarge";
import TLPagebar from "../navbar/appBar/TLPageBar";

const PageContent = styled(Box)`
  padding: 8px 24px 8px 24px;
`;

const ContentCard = styled(Card)`
  height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 25px;
`;

const NoSelectedPatient: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet title="Patient View" />
      <TLPagebar title="Patient View" rightTools={<PatientDrawer />} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 6, mt: 2 }}>
        <Link underline="hover" href="/">
          Home
        </Link>
        <Typography color="text.primary">Patient View</Typography>
      </Breadcrumbs>

      <PageContent>
        <ContentCard>
          <PatientTableLarge />
        </ContentCard>
      </PageContent>
    </React.Fragment>
  );
};

export default NoSelectedPatient;
