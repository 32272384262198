import { jabsCentralApiPrivateBase as api } from "./jabsCentralApiPrivateBase";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    patientGetPatientGet: build.query<
      PatientGetPatientGetApiResponse,
      PatientGetPatientGetApiArg
    >({
      query: (queryArg) => ({
        url: `/patient`,
        params: { limit: queryArg.limit },
      }),
    }),
    patientPatientIdGetPatientPatientIdGet: build.query<
      PatientPatientIdGetPatientPatientIdGetApiResponse,
      PatientPatientIdGetPatientPatientIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/patient/${queryArg.patientId}` }),
    }),
    patientPatientIdAdmissionsGetPatientPatientIdAdmissionsGet: build.query<
      PatientPatientIdAdmissionsGetPatientPatientIdAdmissionsGetApiResponse,
      PatientPatientIdAdmissionsGetPatientPatientIdAdmissionsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/patient/${queryArg.patientId}/admissions`,
      }),
    }),
    patientPatientIdMeasurementsGetPatientPatientIdMeasurementsGet: build.query<
      PatientPatientIdMeasurementsGetPatientPatientIdMeasurementsGetApiResponse,
      PatientPatientIdMeasurementsGetPatientPatientIdMeasurementsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/patient/${queryArg.patientId}/measurements`,
      }),
    }),
    patientPatientIdObservationsGetPatientPatientIdObservationsGet: build.query<
      PatientPatientIdObservationsGetPatientPatientIdObservationsGetApiResponse,
      PatientPatientIdObservationsGetPatientPatientIdObservationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/patient/${queryArg.patientId}/observations`,
      }),
    }),
    patientPatientIdReportsGetPatientPatientIdReportsGet: build.query<
      PatientPatientIdReportsGetPatientPatientIdReportsGetApiResponse,
      PatientPatientIdReportsGetPatientPatientIdReportsGetApiArg
    >({
      query: (queryArg) => ({ url: `/patient/${queryArg.patientId}/reports` }),
    }),
    patientPatientIdScoresGetPatientPatientIdScoresGet: build.query<
      PatientPatientIdScoresGetPatientPatientIdScoresGetApiResponse,
      PatientPatientIdScoresGetPatientPatientIdScoresGetApiArg
    >({
      query: (queryArg) => ({ url: `/patient/${queryArg.patientId}/scores` }),
    }),
    patientPatientIdSummaryCareplanPutPatientPatientIdSummaryCareplanPut:
      build.mutation<
        PatientPatientIdSummaryCareplanPutPatientPatientIdSummaryCareplanPutApiResponse,
        PatientPatientIdSummaryCareplanPutPatientPatientIdSummaryCareplanPutApiArg
      >({
        query: (queryArg) => ({
          url: `/patient/${queryArg.patientId}/summary/careplan`,
          method: "PUT",
          body: queryArg.patientCarePlan,
        }),
      }),
    patientPatientIdSummaryDayPutPatientPatientIdSummaryDayPut: build.mutation<
      PatientPatientIdSummaryDayPutPatientPatientIdSummaryDayPutApiResponse,
      PatientPatientIdSummaryDayPutPatientPatientIdSummaryDayPutApiArg
    >({
      query: (queryArg) => ({
        url: `/patient/${queryArg.patientId}/summary/day`,
        method: "PUT",
        body: queryArg.patientDailySummary,
      }),
    }),
    patientPatientIdSummaryExecutivePutPatientPatientIdSummaryExecutivePut:
      build.mutation<
        PatientPatientIdSummaryExecutivePutPatientPatientIdSummaryExecutivePutApiResponse,
        PatientPatientIdSummaryExecutivePutPatientPatientIdSummaryExecutivePutApiArg
      >({
        query: (queryArg) => ({
          url: `/patient/${queryArg.patientId}/summary/executive`,
          method: "PUT",
          body: queryArg.patientExecutiveSummary,
        }),
      }),
    patientPatientIdSummaryGetPatientPatientIdSummaryGet: build.query<
      PatientPatientIdSummaryGetPatientPatientIdSummaryGetApiResponse,
      PatientPatientIdSummaryGetPatientPatientIdSummaryGetApiArg
    >({
      query: (queryArg) => ({ url: `/patient/${queryArg.patientId}/summary` }),
    }),
    patientPatientIdSummaryGeneratePostPatientPatientIdSummaryGeneratePost:
      build.mutation<
        PatientPatientIdSummaryGeneratePostPatientPatientIdSummaryGeneratePostApiResponse,
        PatientPatientIdSummaryGeneratePostPatientPatientIdSummaryGeneratePostApiArg
      >({
        query: (queryArg) => ({
          url: `/patient/${queryArg.patientId}/summary/generate`,
          method: "POST",
        }),
      }),
    patientPatientIdVitruvianGetPatientPatientIdVitruvianGet: build.query<
      PatientPatientIdVitruvianGetPatientPatientIdVitruvianGetApiResponse,
      PatientPatientIdVitruvianGetPatientPatientIdVitruvianGetApiArg
    >({
      query: (queryArg) => ({
        url: `/patient/${queryArg.patientId}/vitruvian`,
      }),
    }),
    patientPatientIdUpdatesGetPatientPatientIdUpdatesGet: build.query<
      PatientPatientIdUpdatesGetPatientPatientIdUpdatesGetApiResponse,
      PatientPatientIdUpdatesGetPatientPatientIdUpdatesGetApiArg
    >({
      query: (queryArg) => ({ url: `/patient/${queryArg.patientId}/updates` }),
    }),
    patientPatientIdUpdatesPatchPatientPatientIdUpdatesPatch: build.mutation<
      PatientPatientIdUpdatesPatchPatientPatientIdUpdatesPatchApiResponse,
      PatientPatientIdUpdatesPatchPatientPatientIdUpdatesPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/patient/${queryArg.patientId}/updates`,
        method: "PATCH",
        body: queryArg.patientPatientIdUpdatesPatchRequestInner,
      }),
    }),
    patientPatientIdFilesGetPatientPatientIdFilesGet: build.query<
      PatientPatientIdFilesGetPatientPatientIdFilesGetApiResponse,
      PatientPatientIdFilesGetPatientPatientIdFilesGetApiArg
    >({
      query: (queryArg) => ({ url: `/patient/${queryArg.patientId}/files` }),
    }),
    patientPatientIdTimelineDeletePatientPatientIdTimelineDelete:
      build.mutation<
        PatientPatientIdTimelineDeletePatientPatientIdTimelineDeleteApiResponse,
        PatientPatientIdTimelineDeletePatientPatientIdTimelineDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/patient/${queryArg.patientId}/timeline`,
          method: "DELETE",
          params: { event_id: queryArg.eventId },
        }),
      }),
    patientPatientIdTimelineGetPatientPatientIdTimelineGet: build.query<
      PatientPatientIdTimelineGetPatientPatientIdTimelineGetApiResponse,
      PatientPatientIdTimelineGetPatientPatientIdTimelineGetApiArg
    >({
      query: (queryArg) => ({ url: `/patient/${queryArg.patientId}/timeline` }),
    }),
    patientPatientIdTimelinePostPatientPatientIdTimelinePost: build.mutation<
      PatientPatientIdTimelinePostPatientPatientIdTimelinePostApiResponse,
      PatientPatientIdTimelinePostPatientPatientIdTimelinePostApiArg
    >({
      query: (queryArg) => ({
        url: `/patient/${queryArg.patientId}/timeline`,
        method: "POST",
        body: queryArg.patientTimeline,
      }),
    }),
    patientPatientIdTimelinePutPatientPatientIdTimelinePut: build.mutation<
      PatientPatientIdTimelinePutPatientPatientIdTimelinePutApiResponse,
      PatientPatientIdTimelinePutPatientPatientIdTimelinePutApiArg
    >({
      query: (queryArg) => ({
        url: `/patient/${queryArg.patientId}/timeline`,
        method: "PUT",
        body: queryArg.patientTimeline,
      }),
    }),
    filesFileIdDeleteFilesFileIdDelete: build.mutation<
      FilesFileIdDeleteFilesFileIdDeleteApiResponse,
      FilesFileIdDeleteFilesFileIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/files/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    filesFileIdGetFilesFileIdGet: build.query<
      FilesFileIdGetFilesFileIdGetApiResponse,
      FilesFileIdGetFilesFileIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/files/${queryArg.fileId}`,
        params: { dicom: queryArg.dicom },
      }),
    }),
    filesPutFilesPut: build.mutation<
      FilesPutFilesPutApiResponse,
      FilesPutFilesPutApiArg
    >({
      query: (queryArg) => ({
        url: `/files`,
        method: "PUT",
        body: queryArg.file,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as jabsCentralApiPrivate };
export type PatientGetPatientGetApiResponse =
  /** status 200 The list of patients */ PatientList;
export type PatientGetPatientGetApiArg = {
  /** The maximum number of patients to return */
  limit?: number;
};
export type PatientPatientIdGetPatientPatientIdGetApiResponse =
  /** status 200 The patient corresponding to the provided &#x60;patientId&#x60; */ Patient;
export type PatientPatientIdGetPatientPatientIdGetApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
};
export type PatientPatientIdAdmissionsGetPatientPatientIdAdmissionsGetApiResponse =
  /** status 200 The admissions info corresponding to the provided &#x60;patientId&#x60; */ PatientAdmissions;
export type PatientPatientIdAdmissionsGetPatientPatientIdAdmissionsGetApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
};
export type PatientPatientIdMeasurementsGetPatientPatientIdMeasurementsGetApiResponse =
  /** status 200 The Measurements (Vitals) information corresponding to the provided &#x60;patientId&#x60; */ PatientMeasurement;
export type PatientPatientIdMeasurementsGetPatientPatientIdMeasurementsGetApiArg =
  {
    /** The unique identifier of the patient */
    patientId: string;
  };
export type PatientPatientIdObservationsGetPatientPatientIdObservationsGetApiResponse =
  /** status 200 The Observations information corresponding to the provided &#x60;patientId&#x60; */ PatientObservations;
export type PatientPatientIdObservationsGetPatientPatientIdObservationsGetApiArg =
  {
    /** The unique identifier of the patient */
    patientId: string;
  };
export type PatientPatientIdReportsGetPatientPatientIdReportsGetApiResponse =
  /** status 200 The reports corresponding to the provided &#x60;patientId&#x60; */ PatientReports;
export type PatientPatientIdReportsGetPatientPatientIdReportsGetApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
};
export type PatientPatientIdScoresGetPatientPatientIdScoresGetApiResponse =
  /** status 200 The clinical scores associated with the provided patientId */ PatientScores;
export type PatientPatientIdScoresGetPatientPatientIdScoresGetApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
};
export type PatientPatientIdSummaryCareplanPutPatientPatientIdSummaryCareplanPutApiResponse =
  /** status 200 The updated summary */ PatientSummary;
export type PatientPatientIdSummaryCareplanPutPatientPatientIdSummaryCareplanPutApiArg =
  {
    /** The unique identifier of the patient */
    patientId: string;
    patientCarePlan: PatientCarePlan;
  };
export type PatientPatientIdSummaryDayPutPatientPatientIdSummaryDayPutApiResponse =
  /** status 200 The updated summary */ PatientSummary;
export type PatientPatientIdSummaryDayPutPatientPatientIdSummaryDayPutApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
  patientDailySummary: PatientDailySummary;
};
export type PatientPatientIdSummaryExecutivePutPatientPatientIdSummaryExecutivePutApiResponse =
  /** status 200 The updated summary */ PatientSummary;
export type PatientPatientIdSummaryExecutivePutPatientPatientIdSummaryExecutivePutApiArg =
  {
    /** The unique identifier of the patient */
    patientId: string;
    patientExecutiveSummary: PatientExecutiveSummary;
  };
export type PatientPatientIdSummaryGetPatientPatientIdSummaryGetApiResponse =
  /** status 200 The Summary information corresponding to the provided `patientId` */ PatientSummary;
export type PatientPatientIdSummaryGetPatientPatientIdSummaryGetApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
};
export type PatientPatientIdSummaryGeneratePostPatientPatientIdSummaryGeneratePostApiResponse =
  /** status 200 The generate request was successfully created */ Task;
export type PatientPatientIdSummaryGeneratePostPatientPatientIdSummaryGeneratePostApiArg =
  {
    /** The unique identifier of the patient */
    patientId: string;
  };
export type PatientPatientIdVitruvianGetPatientPatientIdVitruvianGetApiResponse =
  /** status 200 The Vitruvian information corresponding to the provided patientId */ PatientVitruvian;
export type PatientPatientIdVitruvianGetPatientPatientIdVitruvianGetApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
};
export type PatientPatientIdUpdatesGetPatientPatientIdUpdatesGetApiResponse =
  /** status 200 The available potential updates for the provided &#x60;patientId&#x60; */ PatientUpdates;
export type PatientPatientIdUpdatesGetPatientPatientIdUpdatesGetApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
};
export type PatientPatientIdUpdatesPatchPatientPatientIdUpdatesPatchApiResponse =
  /** status 200 Successful Response */ Error | /** status 204 Update successfully */ void;
export type PatientPatientIdUpdatesPatchPatientPatientIdUpdatesPatchApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
  patientPatientIdUpdatesPatchRequestInner: PatientPatientIdUpdatesPatchRequestInner[];
};
export type PatientPatientIdFilesGetPatientPatientIdFilesGetApiResponse =
  /** status 200 The reports corresponding to the provided &#x60;patientId&#x60; */ PatientFiles;
export type PatientPatientIdFilesGetPatientPatientIdFilesGetApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
};
export type PatientPatientIdTimelineDeletePatientPatientIdTimelineDeleteApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Update successfully */ void;
export type PatientPatientIdTimelineDeletePatientPatientIdTimelineDeleteApiArg =
  {
    /** The unique identifier of the patient */
    patientId: string;
    /** The unique identifier of the event */
    eventId: string;
  };
export type PatientPatientIdTimelineGetPatientPatientIdTimelineGetApiResponse =
  /** status 200 The Timeline of Events for the provided &#x60;patientId&#x60; */ PatientTimeline;
export type PatientPatientIdTimelineGetPatientPatientIdTimelineGetApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
};
export type PatientPatientIdTimelinePostPatientPatientIdTimelinePostApiResponse =
  /** status 200 The Timeline of Events for the provided &#x60;patientId&#x60; */ PatientTimeline;
export type PatientPatientIdTimelinePostPatientPatientIdTimelinePostApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
  patientTimeline: PatientTimeline;
};
export type PatientPatientIdTimelinePutPatientPatientIdTimelinePutApiResponse =
  /** status 200 The Timeline of Events for the provided &#x60;patientId&#x60; */ PatientTimeline;
export type PatientPatientIdTimelinePutPatientPatientIdTimelinePutApiArg = {
  /** The unique identifier of the patient */
  patientId: string;
  patientTimeline: PatientTimeline;
};
export type FilesFileIdDeleteFilesFileIdDeleteApiResponse =
  /** status 200 OK */ any;
export type FilesFileIdDeleteFilesFileIdDeleteApiArg = {
  /** The unique identifier of the file */
  fileId: string;
};
export type FilesFileIdGetFilesFileIdGetApiResponse = /** status 200 OK */ File;
export type FilesFileIdGetFilesFileIdGetApiArg = {
  /** The unique identifier of the file */
  fileId: string;
  /** Flag to indicate if a DICOM JSON file should be generated and returned for this file retrieval */
  dicom: boolean;
};
export type FilesPutFilesPutApiResponse =
  /** status 200 The updated summary */ File;
export type FilesPutFilesPutApiArg = {
  file: File;
};
export type PatientConditions = {
  id?: string | null;
  conditionCode?: string | null;
  description?: string | null;
  status?: string | null;
};
export type PatientAllergies = {
  id?: string | null;
  allergyCode?: string | null;
  description?: string | null;
  status?: string | null;
};
export type PatientMedications = {
  id?: string | null;
  medicationCode?: string | null;
  description?: string | null;
  status?: string | null;
};
export type PatientExecutiveSummary = {
  id?: string | null;
  day?: number | null;
  name?: string | null;
  description?: string | null;
};
export type PatientDailySummary = {
  id?: string | null;
  day?: number | null;
  summaryDate?: string | null;
  summaryText?: string | null;
};
export type PatientCarePlan = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
};
export type PatientSummaryAccepted = {
  executiveSummary?: PatientExecutiveSummary[] | null;
  dailySummary?: PatientDailySummary[] | null;
  carePlan?: PatientCarePlan[] | null;
};
export type PatientSummary = {
  id: string;
  accepted?: PatientSummaryAccepted | null;
  draft?: PatientSummaryAccepted | null;
};
export type Patient = {
  id: string;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  genderBiological?: string | null;
  genderIdentity?: string | null;
  age?: string | null;
  dob?: string | null;
  admissionDate?: string | null;
  hospitalDuration?: number | null;
  location?: string | null;
  height?: string | null;
  weight?: string | null;
  conditions?: PatientConditions[] | null;
  allergies?: PatientAllergies[] | null;
  medications?: PatientMedications[] | null;
  summary?: PatientSummary | null;
};
export type PatientList = {
  patients?: Patient[] | null;
};
export type Error = {
  code: string;
  message: string;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type PatientAdmissions = {
  id: string;
  name: string;
  description?: string | null;
};
export type PatientMeasurementMeasurementValuesInner = {
  effectiveDateTime?: string | null;
  hospitalDay?: number | null;
  value1?: string | null;
  value2?: string | null;
};
export type PatientMeasurement = {
  measurementType?: string | null;
  measurementValues?: PatientMeasurementMeasurementValuesInner[] | null;
};
export type PatientObservationObservationValuesInner = {
  effectiveDateTime?: string | null;
  hospitalDay?: number | null;
  description?: string | null;
  url?: string | null;
};
export type PatientObservation = {
  observationType?: string | null;
  observationValues?: PatientObservationObservationValuesInner[] | null;
};
export type PatientObservations = {
  id: string;
  observations?: PatientObservation[] | null;
};
export type PatientReportsReportsInnerReportsInner = {
  id?: string | null;
  type?: string | null;
  title?: string | null;
  icon?: string | null;
  dateTime?: string | null;
  color?: string | null;
  bgColor?: string | null;
  content?: string | null;
  approvedBy?: string | null;
};
export type PatientReportsReportsInner = {
  hospitalDay?: number | null;
  hospitalDate?: string | null;
  reports?: PatientReportsReportsInnerReportsInner[] | null;
};
export type PatientReports = {
  id: string;
  reports?: PatientReportsReportsInner[] | null;
};
export type Score = {
  scoreType: string;
  score: number;
  description: string;
};
export type PatientScores = {
  id: string;
  scores: Score[];
};
export type Task = {
  patientId: string;
  taskId?: string | null;
  task?: string | null;
  status?: string | null;
  message?: string | null;
  timestamp?: string | null;
};
export type VitruvianSystem = {
  system?: string | null;
  diagnosis?: string | null;
  visual?: string | null;
};
export type PatientVitruvian = {
  id: string;
  system?: VitruvianSystem[] | null;
};
export type UpdatedConditions = {
  updateId: string;
  condition: string;
  fileId?: string | null;
};
export type UpdatedAllergies = {
  updateId: string;
  allergy: string;
  fileid?: string | null;
};
export type UpdatedMedications = {
  updateId: string;
  medication: string;
  fileId?: string | null;
};
export type PatientUpdates = {
  patientId: string;
  conditions?: UpdatedConditions[] | null;
  allergies?: UpdatedAllergies[] | null;
  medications?: UpdatedMedications[] | null;
};
export type PatientPatientIdUpdatesPatchRequestInner = {
  updateId?: string | null;
  accepted?: boolean | null;
};
export type File = {
  patientId?: string | null;
  fileId?: string | null;
  filename?: string | null;
  filetype?: string | null;
  description?: string | null;
  status?: string | null;
  presignedUrl?: string | null;
  createDate?: string | null;
  updateDate?: string | null;
};
export type PatientFiles = {
  id: string;
  files?: File[] | null;
};
export type PatientTimeline = {
  patientId?: string | null;
  eventId?: string | null;
  eventDateTime?: string | null;
  eventType?: string | null;
  eventTitle?: string | null;
  eventDescription?: string | null;
  fileId?: string | null;
  fileDateTime?: string | null;
  fileUrl?: string | null;
  author?: string | null;
  status?: string | null;
};
export const {
  usePatientGetPatientGetQuery,
  usePatientPatientIdGetPatientPatientIdGetQuery,
  usePatientPatientIdAdmissionsGetPatientPatientIdAdmissionsGetQuery,
  usePatientPatientIdMeasurementsGetPatientPatientIdMeasurementsGetQuery,
  usePatientPatientIdObservationsGetPatientPatientIdObservationsGetQuery,
  usePatientPatientIdReportsGetPatientPatientIdReportsGetQuery,
  usePatientPatientIdScoresGetPatientPatientIdScoresGetQuery,
  usePatientPatientIdSummaryCareplanPutPatientPatientIdSummaryCareplanPutMutation,
  usePatientPatientIdSummaryDayPutPatientPatientIdSummaryDayPutMutation,
  usePatientPatientIdSummaryExecutivePutPatientPatientIdSummaryExecutivePutMutation,
  usePatientPatientIdSummaryGetPatientPatientIdSummaryGetQuery,
  usePatientPatientIdSummaryGeneratePostPatientPatientIdSummaryGeneratePostMutation,
  usePatientPatientIdVitruvianGetPatientPatientIdVitruvianGetQuery,
  usePatientPatientIdUpdatesGetPatientPatientIdUpdatesGetQuery,
  usePatientPatientIdUpdatesPatchPatientPatientIdUpdatesPatchMutation,
  usePatientPatientIdFilesGetPatientPatientIdFilesGetQuery,
  usePatientPatientIdTimelineDeletePatientPatientIdTimelineDeleteMutation,
  usePatientPatientIdTimelineGetPatientPatientIdTimelineGetQuery,
  usePatientPatientIdTimelinePostPatientPatientIdTimelinePostMutation,
  usePatientPatientIdTimelinePutPatientPatientIdTimelinePutMutation,
  useFilesFileIdDeleteFilesFileIdDeleteMutation,
  useFilesFileIdGetFilesFileIdGetQuery,
  useFilesPutFilesPutMutation,
} = injectedRtkApi;
