import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import store from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./components/loaders/Loader";
import { LicenseInfo } from "@mui/x-license";

const clientSideEmotionCache = createEmotionCache();
LicenseInfo.setLicenseKey(
  "61452aef7dfbaf431f6afa895b2de6eeTz0xMDAyNjcsRT0xNzYwODc1NzU4MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI="
);

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { isLoading, error } = useAuth0();
  const { theme } = useTheme();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | sidelight" defaultTitle="sidelight" />
        <Provider store={store}>
          {/* @ts-ignore */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme)}>
              {content}
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
