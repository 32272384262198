import React from "react";

// Importing necessary components from MUI
import { Circle } from "@mui/icons-material";
import { Box } from "@mui/material";
import styled from "@emotion/styled"; // Importing styled from @emotion/styled

// Styled component for LineHolder
const LineHolder = styled(Box)(({ theme }) => ({
  color: theme.palette.text.disabled,
  textAlign: "center",
  width: "55px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  paddingLeft: "5px",
}));

// Styled component for LineHolderTop
const LineHolderTop = styled(LineHolder)(({ theme }) => ({
  paddingTop: "16px",
}));

// Styled component for LineBottom
const LineBottom = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  flexGrow: 1,
  width: "5px",
}));

// Styled component for LineTop
const LineTop = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  height: "16px",
  width: "5px",
  flexShrink: 0,
}));

// Interface defining the props for ChronologyEventMarker component
interface ChronologyEventMarkerProps {
  lineType: string;
}

// ChronologyEventMarker component definition
const ChronologyEventMarker = ({ lineType }: ChronologyEventMarkerProps) => {
  // Switch case to render different line types based on the lineType prop
  switch (lineType) {
    case "single":
      // Render a single event marker
      return (
        <LineHolderTop>
          <Circle />
        </LineHolderTop>
      );

    case "top":
      // Render the top event marker with a bottom line
      return (
        <LineHolderTop>
          <Circle />
          <LineBottom />
        </LineHolderTop>
      );

    case "bottom":
      // Render the bottom event marker with a top line
      return (
        <LineHolder>
          <LineTop />
          <Circle />
        </LineHolder>
      );

    default:
      // Render the middle event marker with top and bottom lines
      return (
        <LineHolder>
          <LineTop />
          <Circle />
          <LineBottom />
        </LineHolder>
      );
  }
};

// Exporting the ChronologyEventMarker component as default
export default ChronologyEventMarker;
