import React from "react";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AiIcon } from "../../icons/TLSvgIcons";
import { AddCircle, AddTask, Delete } from "@mui/icons-material";
import styled from "@emotion/styled";
import {
  usePatientPatientIdUpdatesPatchPatientPatientIdUpdatesPatchMutation,
  PatientPatientIdUpdatesPatchRequestInner,
} from "../../../api/jabsCentralApiPrivate";

// Styled components for UploaderContainer, ExistingList, and ProposedItem
const UploaderContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
  borderRadius: "6px",
  padding: " 16px",
}));
const ExistingList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  margin: "0",
}));
const ProposedItem = styled(Stack)(({ theme }) => ({
  backgroundColor: "#FFFBF7",
  border: `1px solid #FFCEA2`,
  padding: "2px 2px 2px 16px",
  marginTop: "4px",
  borderRadius: "6px",
}));

// Interface defining the props for ListApprover component
interface ListApproverProps {
  existingItems: any[];
  existingHandler: (items: any[]) => void;
  proposedItems: any[];
  proposedHandler: (items: any[]) => void;
  title: string;
}

// ListApprover component definition
const ListApprover = ({
  existingItems,
  existingHandler,
  proposedItems,
  proposedHandler,
  title,
}: ListApproverProps) => {
  // Get the selected patient from the Redux store
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  // Mutation hook for patching patient updates
  const [patchUpdates] =
    usePatientPatientIdUpdatesPatchPatientPatientIdUpdatesPatchMutation();

  // Handler to delete a proposed item locally
  const handleDelete = (index: number) => {
    const newProposed = proposedItems.filter((_, i) => i !== index);
    proposedHandler(newProposed);
  };

  // Handler to delete a proposed item via API
  const handleDeleteApi = (index: number) => {
    const patchData: PatientPatientIdUpdatesPatchRequestInner = {
      updateId: proposedItems[index].id,
      accepted: false,
    };
    console.log("deleted", patchData);
    patchUpdates({
      patientId: selectedPatient,
      patientPatientIdUpdatesPatchRequestInner: [patchData],
    });
    const newProposed = proposedItems.filter((_, i) => i !== index);
    proposedHandler(newProposed);
  };

  // Handler to approve a proposed item
  const handleApprove = (index: number) => {
    console.log("new item", proposedItems[index]);
    const newItem = {
      description: proposedItems[index].item,
      id: existingItems.length,
    };
    const newExisting = [...existingItems, newItem];
    console.log("new existing", newExisting);
    existingHandler(newExisting);

    const patchData: PatientPatientIdUpdatesPatchRequestInner = {
      updateId: proposedItems[index].id,
      accepted: true,
    };
    console.log("approved", patchData);
    patchUpdates({
      patientId: selectedPatient,
      patientPatientIdUpdatesPatchRequestInner: [patchData],
    });
    handleDelete(index);
  };

  // State to manage the anchor element for the Popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  // Handler to open the Popover for adding a new item
  const handleNewItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handler to close the Popover and add the new item to the proposed list
  const handleCloseNewItem = () => {
    if (name) {
      const newItem = { item: name, id: proposedItems.length };
      const newProposed = [...proposedItems, newItem];
      proposedHandler(newProposed);
      setName("");
    }
    setAnchorEl(null);
  };

  // State to manage the open status of the Popover and the name of the new item
  const open = Boolean(anchorEl);
  const [name, setName] = React.useState("");

  // Variable to hold the content for existing items
  let existingItemContent = null;
  if (existingItems.length === 0) {
    existingItemContent = (
      <ListItemButton>
        <ListItemText primary="None" />
      </ListItemButton>
    );
  } else {
    existingItemContent = existingItems.map((item, index) => (
      <ListItemButton key={index} sx={{ background: "#fff" }}>
        <ListItemText primary={item.description} />
      </ListItemButton>
    ));
  }

  return (
    <>
      {/* Header with title and button to add a new item */}
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <IconButton onClick={handleNewItem}>
          <AddCircle />
        </IconButton>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
      </Stack>

      {/* Popover for adding a new item */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseNewItem}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box sx={{ px: 2, py: 2 }}>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <TextField
              id="filled-basic"
              label={title}
              variant="filled"
              size="small"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <IconButton
              color="success"
              onClick={() => {
                handleCloseNewItem();
              }}
            >
              <AddTask />
            </IconButton>
          </Stack>
        </Box>
      </Popover>

      {/* Container for existing and proposed items */}
      <UploaderContainer>
        <ExistingList dense>{existingItemContent}</ExistingList>
        <Collapse in={proposedItems.length > 0}>
          <Stack direction="row" sx={{ alignItems: "center", mt: 6 }}>
            <AiIcon />
            <Typography variant="h6" sx={{ ml: 1 }}>
              Proposed
            </Typography>
          </Stack>

          {/* List of proposed items with approve and delete buttons */}
          {proposedItems.map((item, index) => (
            <ProposedItem
              key={index}
              direction="row"
              sx={{ alignItems: "center" }}
            >
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {item.item}
              </Typography>
              <IconButton
                onClick={() => {
                  handleDeleteApi(index);
                }}
              >
                <Delete />
              </IconButton>
              <IconButton
                color="success"
                onClick={() => {
                  handleApprove(index);
                }}
              >
                <AddTask />
              </IconButton>
            </ProposedItem>
          ))}
        </Collapse>
      </UploaderContainer>
    </>
  );
};

// Exporting the ListApprover component as default
export default ListApprover;
