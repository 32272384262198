export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  apiAudience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
  apiScope: process.env.REACT_APP_AUTH0_API_SCOPE,
};

export const apiConfig = {
  privateUrl: process.env.REACT_APP_API_PRIVATE_BASE_URL,
  privateApiKey: process.env.REACT_APP_API_PRIVATE_APIKEY,
  publicUrl: process.env.REACT_APP_API_PUBLIC_BASE_URL,
  publicApiKey: process.env.REACT_APP_API_PUBLIC_APIKEY,
};

export const featureFlags = {
  dicomViewer: process.env.REACT_APP_FEATURE_FLAG_DICOM_VIEWER,
  dicomViewerURL: process.env.REACT_APP_DICOM_VIEWER_URL,
  audioPodcast: process.env.REACT_APP_FEATURE_FLAG_AUDIO_PODCAST,
};
