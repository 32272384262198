import React from "react";
import { featureFlags } from "../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { usePatientPatientIdFilesGetPatientPatientIdFilesGetQuery } from "../../api/jabsCentralApiPrivate";
import Loader from "../loaders/Loader";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";

const DicomViewerComponent: React.FC<{}> = () => {
  const selectedItem = useSelector(
    (state: RootState) => state.fileSelection.selectedItem
  );
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const {
    data: patientFiles,
    error: patientFilesError,
    isLoading: isPatientFilesLoading,
  } = usePatientPatientIdFilesGetPatientPatientIdFilesGetQuery({
    patientId: selectedPatient,
  });
  console.log("selectedItem:", selectedItem);
  // Now get the DICOM JSON URL from the store

  if (isPatientFilesLoading) {
    return <Loader />;
  }

  if (patientFilesError) {
    return (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientFilesError)
          ? String(patientFilesError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  }

  var presignedUrl = "";
  for (const file of patientFiles?.files || []) {
    if (file.fileId === selectedItem) {
      console.log("file:", file);
      if (file.presignedUrl) {
        presignedUrl = file.presignedUrl;
      }
      break;
    }
  }
  console.log("presignedUrl:", presignedUrl);

  return (
    <div>
      <iframe
        src={`${featureFlags.dicomViewerURL}/dicomjson?url=${encodeURIComponent(
          presignedUrl
        )}`}
        title="DICOM Viewer"
        width="100%"
        height="700px"
      ></iframe>
    </div>
  );
};

export default DicomViewerComponent;
